import SettingsView from '@/views/system/settings/SettingsView.vue'
import { ROUTE } from '@/router/routes'
import { ALoginView, ALogoutView, AUnauthorizedView } from '@anzusystems/common-admin'
import { envConfig } from '@/services/EnvConfigService'
import EmptyView from '@/views/system/EmptyView.vue'

export const systemRoutes = [
  {
    path: '/settings',
    component: SettingsView,
    name: ROUTE.SYSTEM.SETTINGS,
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/login',
    component: ALoginView,
    props: {
      title: 'Anzu CMS admin',
      logoUrl: 'https://web-static-common.smedata.sk/1.10.0/admin-switcher/logo/acms.svg',
      loginUrl: () => envConfig.loginUrl,
    },
    name: ROUTE.SYSTEM.LOGIN,
    meta: {
      requiresAuth: false,
      requiredPermissions: [],
      layout: 'AppLayoutFullscreen',
    },
  },
  {
    path: '/empty',
    component: EmptyView,
    name: ROUTE.SYSTEM.EMPTY,
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/logout',
    component: ALogoutView,
    props: {
      logoutUrl: () => envConfig.logoutUrl,
    },
    name: ROUTE.SYSTEM.LOGOUT,
    meta: {
      requiresAuth: false,
      requiredPermissions: [],
      layout: 'AppLayoutFullscreen',
    },
  },
  {
    path: '/unauthorized',
    component: AUnauthorizedView,
    props: { returnRouteName: ROUTE.SYSTEM.LOGIN },
    name: ROUTE.SYSTEM.UNAUTHORIZED,
    meta: {
      requiresAuth: false,
      requiredPermissions: [],
      layout: 'AppLayoutFullscreen',
    },
  },
]
