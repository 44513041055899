<script lang="ts" setup>
import {
  ADialogToolbar,
  AFormDatetimePicker,
  type DatetimeUTC,
  type DatetimeUTCNullable,
} from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref } from 'vue'
import { useArticleEditActions } from '@/views/cms/article/composables/articleActions'
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'
import dayjs from 'dayjs'

const { t } = useI18n()

const dateTime = ref<DatetimeUTCNullable>(null)

const mainBarDialogsStore = useMainBarDialogsStore()
const { article, saveAndPublish, publishButtonLoading } = useArticleEditActions()

const isDatetimeInPast = (dateTime: DatetimeUTC) => {
  const dateTimeDayjs = dayjs(dateTime)
  const now = dayjs()
  return dateTimeDayjs.isBefore(now)
}

const variantAlreadySetPublishedAt = computed(() => {
  return article.value.dates.publishedAt !== null && !isDatetimeInPast(article.value.dates.publishedAt)
})

const onConfirm = async () => {
  article.value.dates.publishedAt = dateTime.value
  await saveAndPublish(article.value)
  mainBarDialogsStore.closeDialog()
}

const onPublishNow = async () => {
  dateTime.value = null
  article.value.dates.publishedAt = null
  await saveAndPublish(article.value)
  mainBarDialogsStore.closeDialog()
}

const onClose = () => {
  mainBarDialogsStore.closeDialog()
}

onMounted(() => {
  if (variantAlreadySetPublishedAt.value) {
    dateTime.value = article.value.dates.publishedAt
  }
})
</script>

<template>
  <VDialog
    :model-value="true"
    :width="700"
  >
    <VCard>
      <ADialogToolbar @on-cancel="onClose">
        {{ t('cms.articleKind.action.publishLaterDateTime') }}
      </ADialogToolbar>
      <VCardText>
        <p v-if="variantAlreadySetPublishedAt">
          {{ t('cms.articleKind.action.desc.publishAtSet') }}
        </p>
        <AFormDatetimePicker v-model="dateTime" />
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnTertiary @click.stop="onClose">
          {{ t('common.button.cancel') }}
        </ABtnTertiary>
        <ABtnSecondary
          :loading="publishButtonLoading"
          @click.stop="onPublishNow"
        >
          {{ t('cms.articleKind.action.publishNow') }}
        </ABtnSecondary>
        <ABtnPrimary
          :loading="publishButtonLoading"
          @click.stop="onConfirm"
        >
          {{ t('common.button.confirm') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
