<script lang="ts" setup>
import SidebarAppNotificationsItemEmpty from '@/components/system/sidebar/SidebarAppNotificationsItemEmpty.vue'
import SidebarAppNotificationsItemJob from '@/components/system/sidebar/SidebarAppNotificationsItemJob.vue'
import { useAppNotificationActions } from '@/composables/appNotification/appNotificationActions'
import { type AppNotification, AppNotificationMessageType } from '@/composables/appNotification/appNotificationEventBus'
import { useAppNotificationStore } from '@/composables/appNotification/appNotificationStore'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import SidebarAppNotificationsItemTask from '@/components/system/sidebar/SidebarAppNotificationsItemTask.vue'

const { t } = useI18n()
const appNotificationStore = useAppNotificationStore()
const { notifications, mode, showMore, loading, appNotificationSidebar } = storeToRefs(appNotificationStore)

const currentComponent = (notification: AppNotification) => {
  switch (notification.message.type) {
    case AppNotificationMessageType.Job:
      return SidebarAppNotificationsItemJob
    case AppNotificationMessageType.Task:
      return SidebarAppNotificationsItemTask
    default:
      return SidebarAppNotificationsItemEmpty
  }
}

const { clearAll, loadNotifications } = useAppNotificationActions()
</script>

<template>
  <div class="w-100 d-flex pa-2 align-center flex-column">
    <div class="d-flex w-100 align-center flex-row justify-space-between">
      <div class="text-caption font-weight-bold">
        {{ t('notification.appNotification.title') }}
      </div>
      <VBtn
        icon="mdi-arrow-collapse-right"
        size="small"
        variant="text"
        data-cy="button-hide"
        @click.stop="appNotificationSidebar = false"
      />
    </div>
    <div class="d-flex w-100 justify-space-between">
      <VBtnToggle
        v-model="mode"
        :disabled="loading.all"
        mandatory
        density="compact"
      >
        <VBtn
          value="all"
          size="small"
        >
          {{ t('notification.appNotification.all') }}
        </VBtn>
        <VBtn
          value="unread"
          size="small"
        >
          {{ t('notification.appNotification.unread') }}
        </VBtn>
      </VBtnToggle>
      <div>
        <VMenu
          location="bottom end"
          origin="top end"
        >
          <template #activator="{ props: menuProps }">
            <VBtn
              v-bind="menuProps"
              icon
              size="x-small"
              variant="text"
            >
              <VIcon
                size="large"
                icon="mdi-dots-vertical"
              />
              <VTooltip
                anchor="bottom"
                activator="parent"
                :text="t('notification.appNotification.moreActions')"
              />
            </VBtn>
          </template>
          <VList density="compact">
            <VListItem
              :title="t('notification.appNotification.clearAll')"
              @click="clearAll"
            />
          </VList>
        </VMenu>
      </div>
    </div>
  </div>
  <div
    v-if="loading.all"
    class="w-100 d-flex align-center justify-center"
  >
    <VProgressCircular indeterminate />
  </div>
  <VList
    v-else
    density="compact"
    nav
    color="primary"
    class="a-list-notifications"
  >
    <component
      :is="currentComponent(notification)"
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      :is-new="!notification.read"
    />
  </VList>
  <div class="w-100 d-flex align-center justify-center">
    <VBtn
      v-show="showMore && !loading.all"
      :loading="loading.more"
      @click.stop="loadNotifications(false)"
    >
      {{ t('notification.appNotification.moreNotifications') }}
    </VBtn>
  </div>
</template>

<style lang="scss">
.a-list-notifications {
  &.v-list {
    padding-left: 0;
    padding-right: 0;
  }

  .v-list-item-title {
    text-overflow: clip;
    font-weight: normal;
    white-space: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.2rem;
    hyphens: manual;
  }

  .v-list-item__overlay {
    border-radius: 0;
  }
}
</style>
