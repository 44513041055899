import type { ThirdPartyTrackerDto, ThirdPartyTrackerUpsertDto } from '@/types/cms/ThirdPartyTracker'
import { ThirdPartyTrackerServiceDefault } from '@/types/cms/ThirdPartyTracker'

export function useThirdPartyTrackerFactory() {
  const createThirdPartyTrackerDto = (): ThirdPartyTrackerDto => {
    return {
      position: 0,
      service: ThirdPartyTrackerServiceDefault,
      param: '',
      new: false,
    }
  }

  const createThirdPartyTrackerUpsertDto = (thirdPartyTrackers: ThirdPartyTrackerDto[]): ThirdPartyTrackerUpsertDto => {
    return {
      thirdPartyTrackers: thirdPartyTrackers,
    }
  }

  return {
    createThirdPartyTrackerDto,
    createThirdPartyTrackerUpsertDto,
  }
}
