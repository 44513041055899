<script setup lang="ts">
import { ARow, type IntegerIdNullable, isNumber } from '@anzusystems/common-admin'
import SiteGroupAutocomplete from '@/views/cms/siteGroup/components/SiteGroupAutocomplete.vue'
import SiteRemoteAutocomplete from '@/views/cms/site/components/SiteRemoteAutocomplete.vue'
import { onMounted } from 'vue'
import CachedSiteGroupChip from '@/views/cms/siteGroup/components/CachedSiteGroupChip.vue'
import CachedSiteChip from '@/views/cms/site/components/CachedSiteChip.vue'

const props = withDefaults(
  defineProps<{
    fixedSiteGroup?: undefined | IntegerIdNullable
    readonly?: boolean
    labelSiteGroup: string
    labelSite: string
    vSiteGroup?: any
    vSite?: any
  }>(),
  {
    fixedSiteGroup: undefined,
    readonly: false,
    vSiteGroup: null,
    vSite: null
  }
)

const siteGroup = defineModel<IntegerIdNullable>('siteGroup', { required: true })
const site = defineModel<IntegerIdNullable>('site', { required: true })

const onSiteGroupUpdate = () => {
  site.value = null
}

onMounted(() => {
  if (isNumber(props.fixedSiteGroup)) {
    siteGroup.value = props.fixedSiteGroup
  }
})
</script>

<template>
  <template v-if="readonly">
    <ARow :title="labelSiteGroup">
      <CachedSiteGroupChip :id="siteGroup" />
    </ARow>
    <ARow :title="labelSite">
      <CachedSiteChip :id="site" />
    </ARow>
  </template>
  <template v-else>
    <ARow>
      <SiteGroupAutocomplete
        v-model="siteGroup"
        :label="labelSiteGroup"
        :v="vSiteGroup"
        required
        :disabled="isNumber(fixedSiteGroup)"
        @update:model-value="onSiteGroupUpdate"
      />
    </ARow>
    <ARow>
      <SiteRemoteAutocomplete
        :key="siteGroup + ''"
        v-model="site"
        :v="vSite"
        :site-group-id="siteGroup"
        :label="labelSite"
        site-group-required
        required
      />
    </ARow>
  </template>
</template>
