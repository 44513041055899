import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const ArticleIcon = {
  None: 'none',
  Gallery: 'gallery',
  Video: 'video',
  VideoBig: 'videoBig',
  Audio: 'audio',
  Tv: 'tv',
  Live: 'live',
} as const
export type ArticleIconType = (typeof ArticleIcon)[keyof typeof ArticleIcon]

export function useArticleIcon() {
  const { t } = useI18n()

  const articleIconOptions = ref<ValueObjectOption<ArticleIconType>[]>([
    {
      value: ArticleIcon.None,
      title: t('cms.articleKind.articleIcon.none'),
    },
    {
      value: ArticleIcon.Gallery,
      title: t('cms.articleKind.articleIcon.gallery'),
    },
    {
      value: ArticleIcon.Video,
      title: t('cms.articleKind.articleIcon.video'),
    },
    {
      value: ArticleIcon.VideoBig,
      title: t('cms.articleKind.articleIcon.videoBig'),
    },
    {
      value: ArticleIcon.Audio,
      title: t('cms.articleKind.articleIcon.audio'),
    },
    {
      value: ArticleIcon.Tv,
      title: t('cms.articleKind.articleIcon.tv'),
    },
    {
      value: ArticleIcon.Live,
      title: t('cms.articleKind.articleIcon.live'),
    },
  ])

  const getArticleIconOption = (value: ArticleIconType) => {
    return articleIconOptions.value.find((item) => item.value === value)
  }

  return {
    articleIconOptions,
    getArticleIconOption,
  }
}
