<script lang="ts" setup>
import { AFormRemoteAutocomplete, type IntegerId, type IntegerIdNullable, isNumber } from '@anzusystems/common-admin'
import { computed, onMounted } from 'vue'
import { useDesignSettingsFilter } from '@/model/cms/filter/DesignSettingsFilter'
import { useDesignSettingsSelectActions } from '@/views/cms/designSettings/composables/designSettingsActions'

const props = withDefaults(
  defineProps<{
    modelValue: IntegerIdNullable | IntegerId[]
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    onlyArticleUsable?: boolean
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    onlyArticleUsable: false,
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', data: IntegerIdNullable | IntegerId[]): void
}>()
const value = computed({
  get(): IntegerIdNullable | IntegerId[] {
    return isNumber(props.modelValue) && props.modelValue === 0 ? null : props.modelValue
  },
  set(newValue: IntegerIdNullable | IntegerId[]) {
    emit('update:modelValue', newValue)
  },
})

const { fetchItems, fetchItemsByIds } = useDesignSettingsSelectActions()

const innerFilter = useDesignSettingsFilter()

onMounted(() => {
  if (props.onlyArticleUsable) {
    innerFilter.articleUsable.model = true
  }
})
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="value"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :multiple="multiple"
    filter-by-field="title"
    clearable
  />
</template>
