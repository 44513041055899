import type { Immutable } from '@anzusystems/common-admin'
import { ensureUniqueValues, objectDeepFreeze, type UniqueValues } from '@anzusystems/common-admin'

const tableKeys = {
  article: 'table_article',
  advertSettings: 'table_advertSettings',
  deskArticle: 'table_deskArticle',
  userArticle: 'table_userArticle',
  articleAdvertSettings: 'table_articleAdvertSettings',
  articleArchive: 'table_articleArchive',
  articleIntention: 'table_articleIntention',
  articleStage: 'table_articleStage',
  stageDashboard: 'table_stageDashboard',
  author: 'table_author',
  autoDistribution: 'table_autoDistribution',
  box: 'table_box',
  boxGroup: 'table_boxGroup',
  countdown: 'table_countdown',
  crossBox: 'table_crossBox',
  designSettings: 'table_designSettings',
  desk: 'table_desk',
  event: 'table_event',
  externalSnippet: 'table_externalSnippet',
  faq: 'table_faq',
  gallery: 'table_gallery',
  inbox: 'table_inbox',
  job: 'table_job',
  keyword: 'table_keyword',
  keywordManagement: 'table_keywordManagement',
  layoutTemplate: 'table_layoutTemplate',
  linked: 'table_linked',
  newsletter: 'table_newsletter',
  newsletterPromo: 'table_newsletterPromo',
  newsroomSection: 'table_newsroomSection',
  notification: 'table_notification',
  organization: 'table_organization',
  organizationPage: 'table_organizationPage',
  page: 'table_page',
  paywall: 'table_paywall',
  person: 'table_person',
  personPage: 'table_personPage',
  personPosition: 'table_personPosition',
  poll: 'table_poll',
  promoLink: 'table_promoLink',
  publicExport: 'table_publicExport',
  quiz: 'table_quiz',
  review: 'table_review',
  route: 'table_route',
  routeSettings: 'table_routeSettings',
  rubric: 'table_rubric',
  site: 'table_site',
  siteGroup: 'table_siteGroup',
  stage: 'table_stage',
  superAuthor: 'table_superAuthor',
  targetPosition: 'table_targetPosition',
  deskTask: 'table_deskTask',
  task: 'table_task',
  userTask: 'table_userTask',
  timeline: 'table_timeline',
  trendingArticleGroup: 'table_trendingArticleGroup',
  extSystemCallback: 'table_extSystemCallback',
  extSystem: 'table_extSystem',
  extSystemMapKindArticle: 'table_extSystemMapKindArticle',
  extSystemMapKindAuthor: 'table_extSystemMapKindAuthor',
  extSystemMapKindRubric: 'table_extSystemMapKindRubric',
  anzuUser: 'table_anzuUser',
  customForm: 'table_customForm',
  log: 'table_log',
  permissionGroup: 'table_permissionGroup',
  externalContent: 'table_externalContent',
} as const

export const TABLE_KEY: Immutable<UniqueValues<typeof tableKeys>> = objectDeepFreeze(ensureUniqueValues(tableKeys))
