import { apiAnyRequest, apiDeleteOne, type FilterBag, type IntegerId, type Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { Notification } from '@/types/cms/Notification'

const END_POINT = '/adm/v1/notifications'
export const ENTITY = 'notification'

export const fetchNotificationList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<Notification[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchNotificationListByIds = (ids: number[]) =>
  apiFetchByIds<Notification[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchNotification = (id: number) =>
  apiFetchOne<Notification>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createNotification = (data: Notification) =>
  apiCreateOne<Notification>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateNotification = (id: IntegerId, data: Notification) =>
  apiUpdateOne<Notification>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteNotification = (id: IntegerId) =>
  apiDeleteOne<Notification>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const sendNotification = (id: IntegerId) =>
  apiAnyRequest<Notification>(cmsClient, 'POST', END_POINT + '/:id/send', { id }, undefined, SYSTEM_CMS, ENTITY)
