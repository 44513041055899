<script setup lang="ts">
import { useArticleOneStore } from '@/stores/cms/articleStore'
import type { CollabRoom } from '@anzusystems/common-admin'
import { AFormTextField, ARow, type CollabStatusType, useCollabHelpers } from '@anzusystems/common-admin'
import { useArticleUpdateValidation } from '@/views/cms/article/composables/articleValidations'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { storeToRefs } from 'pinia'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { createCollabFieldConfig } = useCollabHelpers()
const { cachedUsers } = useCachedUsers()

const articleOneStore = useArticleOneStore()
const { article } = storeToRefs(articleOneStore)

const { v$ } = useArticleUpdateValidation(article)
</script>

<template>
  <ARow>
    <AFormTextField
      v-model="article.canonicalUrl"
      :disabled="readonly"
      :v="v$.article.canonicalUrl"
      :collab="createCollabFieldConfig('seo.title', collabRoom, cachedUsers)"
    />
  </ARow>
</template>
