<script setup lang="ts">
import { ArticleLockType } from '@/model/cms/valueObject/ArticleLockType'
import { useArticleOneStore } from '@/stores/cms/articleStore'
import type { CollabRoom } from '@anzusystems/common-admin'
import { ARow, type CollabStatusType, useI18n } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { article } = storeToRefs(useArticleOneStore())

const { t } = useI18n()

const label = computed(() => {
  return article.value.attributesStandard.lockType === ArticleLockType.Locked
    ? t('cms.articleKind.articleLockType.locked')
    : t('cms.articleKind.articleLockType.free')
})
</script>

<template>
  <!-- todo: collab + auto add/remove contentLock embed on change + when embed is removed, auto toggle this field -->
  <ARow>
    <VSwitch
      v-model="article.attributesStandard.lockType"
      :disabled="readonly || !isModerator"
      :label="label"
      :true-value="ArticleLockType.Locked"
      :false-value="ArticleLockType.Free"
    />
  </ARow>
</template>
