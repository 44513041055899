<script lang="ts" setup>
import { AActionCreateButton, ACard, defineBreadcrumbs } from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import NotificationDatatable from '@/views/cms/notification/components/NotificationDatatable.vue'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import { useNotificationListActions } from '@/views/cms/notification/composables/notificationActions'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { listLoading } = useNotificationListActions()
const { t } = useI18n()

const breadcrumbs = defineBreadcrumbs(
  computed(() => [{ title: t('breadcrumb.cms.notification.list'), routeName: ROUTE.CMS.NOTIFICATION.LIST }])
)
</script>

<template>
  <ActionbarWrapper :breadcrumbs="breadcrumbs">
    <template #buttons>
      <AActionCreateButton :route-name="ROUTE.CMS.NOTIFICATION.CREATE" />
    </template>
  </ActionbarWrapper>

  <ACard :loading="listLoading">
    <VCardText>
      <NotificationDatatable />
    </VCardText>
  </ACard>
</template>
