import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const extSystemRoutes: RouteRecordRaw[] = [
  {
    path: '/ext-system',
    name: ROUTE.CMS.EXT_SYSTEM.LIST,
    component: () => import('@/views/cms/extSystem/ExtSystemListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system/create',
    name: ROUTE.CMS.EXT_SYSTEM.CREATE,
    component: () => import('@/views/cms/extSystem/ExtSystemCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system/:id(\\d+)/edit',
    name: ROUTE.CMS.EXT_SYSTEM.EDIT,
    component: () => import('@/views/cms/extSystem/ExtSystemEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_READ, ACL.CMS_EXT_SYSTEM_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system/:id(\\d+)',
    name: ROUTE.CMS.EXT_SYSTEM.DETAIL,
    component: () => import('@/views/cms/extSystem/ExtSystemDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
