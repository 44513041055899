import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import type { Notification } from '@/types/cms/Notification'

export const NotificationValidationSymbol = Symbol.for('anzu:cms:notification-validation-scope')

export function useNotificationValidation(notification: Ref<Notification>) {
  const { maxLength, minLength, required, minValue } = useValidate()

  const rules = {
    notification: {
      texts: {
        headline: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        summary: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        overline: {
          maxLength: maxLength(255),
        },
      },
      site: {
        required,
        minValue: minValue(1),
      },
      siteGroup: {
        required,
        minValue: minValue(1),
      },
    },
  }
  const v$ = useVuelidate(rules, { notification }, { $scope: NotificationValidationSymbol })

  return {
    v$,
  }
}
