import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const extSystemMapKindArticleRoutes: RouteRecordRaw[] = [
  {
    path: '/ext-system-map-kind-article',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_ARTICLE.LIST,
    component: () => import('@/views/cms/extSystemMapKindArticle/ExtSystemMapKindListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-article/create',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_ARTICLE.CREATE,
    component: () => import('@/views/cms/extSystemMapKindArticle/ExtSystemMapKindArticleCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-article/:id(\\d+)/edit',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_ARTICLE.EDIT,
    component: () => import('@/views/cms/extSystemMapKindArticle/ExtSystemMapKindArticleEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_READ, ACL.CMS_EXT_SYSTEM_MAP_KIND_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-article/:id(\\d+)',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_ARTICLE.DETAIL,
    component: () => import('@/views/cms/extSystemMapKindArticle/ExtSystemMapKindArticleDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
