import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/articlePublicPreviewApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

export function useArticlePublicPreviewFilter() {
  return reactive({
    id: {
      ...makeFilter({ name: 'id', variant: 'in' }),
    },
    article: {
      ...makeFilter({ name: 'article' }),
    },
  })
}
