import { useArticleFilter } from '@/model/cms/filter/ArticleFilter'
import type { ArticleStatusType } from '@/model/cms/valueObject/ArticleStatus'
import { SYSTEM_CMS } from '@/model/systems'
import { cmsClient } from '@/services/api/clients/cmsClient'
import {
  type ArticleListResponse,
  filterUrlFilterBag,
  mapVersionDataStandard,
} from '@/services/api/cms/articleApiHelpers'
import type { Article, ArticleRelatedEntities } from '@/types/cms/Article/Article'
import type { ArticleCreateDto } from '@/types/cms/Article/ArticleCreateDto'
import type { DocId, FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiAnyRequest,
  apiCreateOne,
  apiDeleteOne,
  apiFetchList,
  apiFetchOne,
  apiGenerateListQuery,
  apiUpdateOne,
  makeFilterHelper,
  usePagination,
} from '@anzusystems/common-admin'
import { reactive } from 'vue'
import type { ThirdPartyTrackerUpsertDto } from '@/types/cms/ThirdPartyTracker'

const END_POINT = '/adm/v1/article-kind'
const END_POINT_STANDARD = END_POINT + '/standard'
export const ENTITY = 'articleKind'
export const ENTITY_STANDARD = 'articleKindStandard'

export const fetchArticleListByDocIds = (ids: DocId[]) => {
  const pagination = usePagination()
  pagination.rowsPerPage = ids.length * 2
  const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)
  const filterBag = reactive({
    _elastic: {
      ...makeFilter({ exclude: true }),
    },
    docIds: {
      ...makeFilter({ name: 'docIds' }),
    },
  })
  filterBag.docIds.model = ids

  return apiFetchList<Article[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)
}

export const fetchArticleList = (pagination: Pagination, filterBag: FilterBag) => {
  filterUrlFilterBag(filterBag)
  return apiFetchList<Article[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)
}

export const fetchArticleListVersionData = async (pagination: Pagination, filterBag: FilterBag) => {
  filterUrlFilterBag(filterBag)
  filterBag.discriminator.model = 'standard'
  const res = await apiAnyRequest<ArticleListResponse>(
    cmsClient,
    'GET',
    END_POINT + '/search' + apiGenerateListQuery(pagination, filterBag),
    {},
    undefined,
    SYSTEM_CMS,
    ENTITY
  )
  pagination.hasNextPage = res.hasNextPage
  pagination.currentViewCount = res.data.length

  return mapVersionDataStandard(res.data, res.versionsData)
}

export const fetchArticle = (id: IntegerId) =>
  apiFetchOne<Article>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const fetchArticleByDocIdVersion = (docId: DocId, version: number) =>
  apiFetchOne<Article>(cmsClient, END_POINT + '/:docId/:version', { docId, version }, SYSTEM_CMS, ENTITY)

export const fetchArticleRelatedEntities = (id: IntegerId) =>
  apiFetchOne<ArticleRelatedEntities>(
    cmsClient,
    END_POINT_STANDARD + '/:id' + '/related-entities',
    { id },
    SYSTEM_CMS,
    ENTITY_STANDARD
  )

export const createArticle = (data: ArticleCreateDto) => {
  return apiCreateOne<ArticleCreateDto, Article>(cmsClient, data, END_POINT_STANDARD, {}, SYSTEM_CMS, ENTITY_STANDARD)
}

export const updateArticle = (id: IntegerId, data: Article) =>
  apiUpdateOne<Article>(cmsClient, data, END_POINT + '/standard/:id', { id }, SYSTEM_CMS, ENTITY)

export const apiUpdatePromoLinkItems = (id: IntegerId) => {
  return apiAnyRequest<Article>(
    cmsClient,
    'PATCH',
    END_POINT + '/update-promo-link-items/:id',
    { id },
    null,
    SYSTEM_CMS,
    'articleKind'
  )
}

export const deleteArticle = (id: IntegerId) =>
  apiDeleteOne<Article>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const changeArticleStatus = (id: IntegerId, newStatus: ArticleStatusType) =>
  apiAnyRequest<Article>(
    cmsClient,
    'PATCH',
    END_POINT + '/:id/status/:newStatus',
    { id, newStatus },
    null,
    SYSTEM_CMS,
    ENTITY
  )

export const createArticleDraftFromPublished = (id: IntegerId) =>
  apiAnyRequest<Article>(cmsClient, 'PATCH', END_POINT + '/draft/:id', { id }, null, SYSTEM_CMS, ENTITY)

export const fetchArticleListByDocId = (docId: DocId) => {
  const pagination = usePagination()
  const filterBag = useArticleFilter()
  filterBag.docId.model = docId

  return apiFetchList<Article[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)
}

export const upsertArticleThirdPartyTrackers = (id: IntegerId, data: ThirdPartyTrackerUpsertDto) =>
  apiAnyRequest<ThirdPartyTrackerUpsertDto, Article>(
    cmsClient,
    'PATCH',
    END_POINT_STANDARD + '/:id' + '/third-party-trackers',
    { id },
    data,
    SYSTEM_CMS,
    ENTITY_STANDARD
  )
