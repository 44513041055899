import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import { ArticleListLayoutDefault } from '@/types/cms/ContentItemKind/ContentItemKindArticleList'
import type { ContentItemKindBoxHistory } from '@/types/cms/ContentItemKind/ContentItemKindBoxHistory'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindBoxHistoryFactory() {
  const create = (): ContentItemKindBoxHistory => {
    return {
      ...createAbstractContentItemKind(),
      title: '',
      box: null,
      allowListing: true,
      itemsLimit: 20,
      listLayout: ArticleListLayoutDefault,
      useOverline: false,
      maxPage: 30,
      maxPageReachedRedirectPage: null,
      teleportedIdentifiers: {},
      statsDisplayEnabled: false,
      statsSemaphoreEnabled: false,
      statsSemaphoreOwlTimeSpentAggregationId: null,
      statsSemaphoreIntervalA: 3600,
      statsSemaphoreIntervalB: 3600,
      discriminator: ContentItemDiscriminator.BoxHistory,
      _resourceName: 'contentItemKindBoxHistory',
    }
  }

  return {
    create,
  }
}
