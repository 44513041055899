<script setup lang="ts">
import { AFormTextarea, AFormValueObjectOptionsSelect } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import { type ThirdPartyTrackerDto, ThirdPartyTrackerService } from '@/types/cms/ThirdPartyTracker'
import { useArticleThirdPartyTrackerValidation } from '@/views/cms/articleThirdPartyTracker/composables/articleThirdPartyTrackerValidation'
import { useThirdPartyTrackerService } from '@/model/cms/valueObject/ThirdPartyTrackerService'
import { computed } from 'vue'

withDefaults(
  defineProps<{
    readonly: boolean
  }>(),
  {}
)

const modelValue = defineModel<ThirdPartyTrackerDto>({ required: true })

const { v$ } = useArticleThirdPartyTrackerValidation(modelValue)

const { thirdPartyTrackerServiceOptions } = useThirdPartyTrackerService()

const exampleCode = computed(() => {
  if (modelValue.value.service === ThirdPartyTrackerService.Gemius) {
    return '<img src="https://sk.hit.gemius.pl/_[TIMESTAMP]/redot.gif?id=nLiVJkyPCQTq.nhH3giL0JRL33i6Afuh7EVC.oIn03z.L7/fastid=ggngaeifpgfndclemwpwixkxfukh/stparam=pmfpktngkx/nc=0/gdpr=0/gdpr_consent="/>'
  }
  if (modelValue.value.service === ThirdPartyTrackerService.AdForm) {
    return '<img src="https://track.adform.net/adfserve/?bn=72536701;1x1inv=1;srctype=3;ord=[timestamp]" style="display:none;"/>'
  }
  if (modelValue.value.service === ThirdPartyTrackerService.DoubleClick) {
    return '<img src="https://ad.doubleclick.net/ddm/trackimp/N167004.161487SME.SK/B32469728.402868919;dc_trk_aid=594708937;dc_trk_cid=128369098;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ltd=;dc_tdv=1" attributionsrc border="0" height="1" width="1" alt="Advertisement"/>'
  }
  return '<img src="https://cm1.jhmt.cz/i/v1=/v2=/v4=CA5949F4A949/t=PageViewsMeasurement/ci=GroupM%20SK/ca=Kampan20240514_tag15/sd1=/sd2=/sd3=/sd4=/cr=/crn=/li=/lin=/pl=/pln=/me=/men=/v106=/v105=/v103=/r=[timestamp]/v12=JHMTIMG/e=impression" style="display:none;" />'
})

const { t } = useI18n()
</script>

<template>
  <VRow class="mt-3">
    <VCol cols="3">
      <AFormValueObjectOptionsSelect
        v-model="modelValue.service"
        :items="thirdPartyTrackerServiceOptions"
        :v="v$.thirdPartyTracker.service"
        :readonly="readonly"
        :label="t('cms.thirdPartyTracker.model.service')"
      />
    </VCol>
    <VCol cols="9">
      <AFormTextarea
        v-model="modelValue.param"
        :label="t('cms.thirdPartyTracker.model.param')"
        :v="v$.thirdPartyTracker.param"
        :readonly="readonly"
        :rows="3"
        class="mb-1"
      />
      <span class="text-caption">
        <span class="font-weight-bold">{{ t('cms.thirdPartyTracker.meta.exampleCode') }}:</span>
        {{ exampleCode }}
      </span>
    </VCol>
  </VRow>
</template>
