import type { ValueObjectOption } from '@anzusystems/common-admin'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ThirdPartyTrackerServiceType } from '@/types/cms/ThirdPartyTracker'
import { ThirdPartyTrackerService } from '@/types/cms/ThirdPartyTracker'

export function useThirdPartyTrackerService() {
  const { t } = useI18n()

  const thirdPartyTrackerServiceOptions = ref<ValueObjectOption<ThirdPartyTrackerServiceType>[]>([
    {
      value: ThirdPartyTrackerService.Gemius,
      title: t('cms.thirdPartyTracker.service.gemius'),
    },
    {
      value: ThirdPartyTrackerService.AdForm,
      title: t('cms.thirdPartyTracker.service.adForm'),
    },
    {
      value: ThirdPartyTrackerService.DoubleClick,
      title: t('cms.thirdPartyTracker.service.doubleClick'),
    },
    {
      value: ThirdPartyTrackerService.Nielsen,
      title: t('cms.thirdPartyTracker.service.nielsen'),
    },
  ])

  const getThirdPartyTrackerServiceOption = (value: ThirdPartyTrackerServiceType) => {
    return thirdPartyTrackerServiceOptions.value.find((item) => item.value === value)
  }

  return {
    thirdPartyTrackerServiceOptions,
    getThirdPartyTrackerServiceOption,
  }
}
