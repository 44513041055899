<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import {
  ACard,
  ALanguageSelect,
  AThemeSelect,
  AvailableLanguagesSymbol,
  DefaultLanguageSymbol,
  type LanguageCode,
  modifyLanguageSettings,
} from '@anzusystems/common-admin'
import { inject } from 'vue'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import { useAuth } from '@/composables/auth/auth'
import type { User } from '@/types/cms/User'

const { t } = useI18n()
const configAvailableLanguages = inject<LanguageCode[]>(AvailableLanguagesSymbol, [])
const configDefaultLanguage = inject<LanguageCode>(DefaultLanguageSymbol, 'sk')
const { addMessages } = modifyLanguageSettings(configAvailableLanguages, configDefaultLanguage)

const loadLanguageMessages = async (code: LanguageCode | 'default') => {
  if (code === 'default' || code === 'xx') return
  try {
    const messages = await import(`../../../locales/${code}.ts`)
    addMessages(code, messages.default)
  } catch (e) {
    console.error('Unable to load language translation messages.')
  }
}

const afterLanguageChange = async (language: LanguageCode) => {
  await loadLanguageMessages(language)
}

const { useCurrentUser } = useAuth()
const { isSuperAdmin } = useCurrentUser<User>('cms')
</script>

<template>
  <ActionbarWrapper />

  <ACard>
    <VCardText>
      <VRow>
        <VCol cols="6">
          <VRow
            align="center"
            class="pb-2"
          >
            <VCol cols="3">
              {{ t('system.settings.locale') }}
            </VCol>
            <VCol>
              <ALanguageSelect
                :is-administrator="isSuperAdmin"
                @after-change="afterLanguageChange"
              />
            </VCol>
          </VRow>
          <VRow
            align="center"
            class="pb-2"
          >
            <VCol cols="3">
              {{ t('system.settings.theme') }}
            </VCol>
            <VCol>
              <AThemeSelect />
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VCardText>
  </ACard>
</template>
