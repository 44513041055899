import type { SYSTEM_CMS } from '@/model/systems'
import type { ContentItemKindAdvert } from '@/types/cms/ContentItemKind/ContentItemKindAdvert'
import type { ContentItemKindAggregation } from '@/types/cms/ContentItemKind/ContentItemKindAggregation'
import type { ContentItemKindArchive } from '@/types/cms/ContentItemKind/ContentItemKindArchive'
import type { ContentItemKindArticleList } from '@/types/cms/ContentItemKind/ContentItemKindArticleList'
import type { ContentItemKindBox } from '@/types/cms/ContentItemKind/ContentItemKindBox'
import type { ContentItemKindBoxHistory } from '@/types/cms/ContentItemKind/ContentItemKindBoxHistory'
import type {
  ContentItemDetailKindBreakingNews,
  ContentItemKindBreakingNews,
} from '@/types/cms/ContentItemKind/ContentItemKindBreakingNews'
import type { ContentItemKindCountdown } from '@/types/cms/ContentItemKind/ContentItemKindCountdown'
import type { ContentItemKindCrossBox } from '@/types/cms/ContentItemKind/ContentItemKindCrossBox'
import type { ContentItemKindFaq } from '@/types/cms/ContentItemKind/ContentItemKindFaq'
import type {
  ContentItemDetailKindImportantNews,
  ContentItemKindImportantNews,
} from '@/types/cms/ContentItemKind/ContentItemKindImportantNews'
import type { ContentItemKindLinkedList } from '@/types/cms/ContentItemKind/ContentItemKindLinkedList'
import type { ContentItemKindNewsroomSection } from '@/types/cms/ContentItemKind/ContentItemKindNewsroomSection'
import type { ContentItemKindPageHeader } from '@/types/cms/ContentItemKind/ContentItemKindPageHeader'
import type { ContentItemKindRempBanner } from '@/types/cms/ContentItemKind/ContentItemKindRempBanner'
import type { ContentItemKindSearch } from '@/types/cms/ContentItemKind/ContentItemKindSearch'
import type { ContentItemKindTeleport } from '@/types/cms/ContentItemKind/ContentItemKindTeleport'
import type { ContentItemKindThematicBox } from '@/types/cms/ContentItemKind/ContentItemKindThematicBox'
import type { ContentItemKindTimeline } from '@/types/cms/ContentItemKind/ContentItemKindTimeline'
import type { ContentItemKindTrendingArticleList } from '@/types/cms/ContentItemKind/ContentItemKindTrendingArticleList'
import type { ContentItemKindWeather } from '@/types/cms/ContentItemKind/ContentItemKindWeather'
import type { ContentItemKindUserWeather } from '@/types/cms/ContentItemKind/ContentItemKindUserWeather'
import type { ContentItemKindWysiwyg } from '@/types/cms/ContentItemKind/ContentItemKindWysiwyg'
import type { DocIdNullable, IntegerId, IntegerIdNullable, SortableItemDataAware } from '@anzusystems/common-admin'
import type { ContentItemKindDangerBox } from '@/types/cms/ContentItemKind/ContentItemKindDangerBox'

export const ContentItemDiscriminator = {
  Advert: 'advert',
  Aggregation: 'aggregation',
  Archive: 'archive',
  ArticleList: 'articleList',
  Box: 'box',
  BoxHistory: 'boxHistory',
  BreakingNews: 'breakingNews',
  Countdown: 'countdown',
  CrossBox: 'crossBox',
  DangerBox: 'dangerBox',
  Faq: 'faq',
  ImportantNews: 'importantNews',
  LinkedList: 'linkedList',
  NewsroomSection: 'newsroomSection',
  TrendingArticleList: 'trendingArticleList',
  PageHeader: 'pageHeader',
  RempBanner: 'rempBanner',
  Search: 'search',
  Teleport: 'teleport',
  ThematicBox: 'thematicBox',
  Timeline: 'timeline',
  UserWeather: 'userWeather',
  Weather: 'weather',
  Wysiwyg: 'wysiwyg',
} as const
export const ContentItemDiscriminatorDefault: ContentItemDiscriminatorType = ContentItemDiscriminator.ArticleList
export type ContentItemDiscriminatorType = (typeof ContentItemDiscriminator)[keyof typeof ContentItemDiscriminator]

export type ContentItemDiscriminatorTypeMap = {
  [ContentItemDiscriminator.Advert]: ContentItemKindAdvert
  [ContentItemDiscriminator.Aggregation]: ContentItemKindAggregation
  [ContentItemDiscriminator.Archive]: ContentItemKindArchive
  [ContentItemDiscriminator.ArticleList]: ContentItemKindArticleList
  [ContentItemDiscriminator.Box]: ContentItemKindBox
  [ContentItemDiscriminator.BoxHistory]: ContentItemKindBoxHistory
  [ContentItemDiscriminator.BreakingNews]: ContentItemKindBreakingNews
  [ContentItemDiscriminator.Countdown]: ContentItemKindCountdown
  [ContentItemDiscriminator.CrossBox]: ContentItemKindCrossBox
  [ContentItemDiscriminator.DangerBox]: ContentItemKindDangerBox
  [ContentItemDiscriminator.Faq]: ContentItemKindFaq
  [ContentItemDiscriminator.LinkedList]: ContentItemKindLinkedList
  [ContentItemDiscriminator.NewsroomSection]: ContentItemKindNewsroomSection
  [ContentItemDiscriminator.TrendingArticleList]: ContentItemKindTrendingArticleList
  [ContentItemDiscriminator.ImportantNews]: ContentItemKindImportantNews
  [ContentItemDiscriminator.PageHeader]: ContentItemKindPageHeader
  [ContentItemDiscriminator.RempBanner]: ContentItemKindRempBanner
  [ContentItemDiscriminator.Search]: ContentItemKindSearch
  [ContentItemDiscriminator.Teleport]: ContentItemKindTeleport
  [ContentItemDiscriminator.ThematicBox]: ContentItemKindThematicBox
  [ContentItemDiscriminator.Timeline]: ContentItemKindTimeline
  [ContentItemDiscriminator.UserWeather]: ContentItemKindUserWeather
  [ContentItemDiscriminator.Weather]: ContentItemKindWeather
  [ContentItemDiscriminator.Wysiwyg]: ContentItemKindWysiwyg
}
export type ContentItemKindTypes = ContentItemDiscriminatorTypeMap[keyof ContentItemDiscriminatorTypeMap]

export interface ContentItemKind extends SortableItemDataAware {
  id: IntegerId
  forcedSynchronicity: boolean
  teleportToIdentifier: string
  position: number
  readonly pageContent?: IntegerIdNullable
  readonly autoDistribution?: IntegerIdNullable
  readonly aggregation?: IntegerIdNullable
  readonly discriminator: ContentItemDiscriminatorType
  _system: typeof SYSTEM_CMS
}

export const ContentItemDetailDiscriminator = {
  BreakingNews: 'breakingNews',
  ImportantNews: 'importantNews',
} as const
export const ContentItemDetailDiscriminatorDefault: ContentItemDetailDiscriminatorType =
  ContentItemDetailDiscriminator.BreakingNews
export type ContentItemDetailDiscriminatorType =
  (typeof ContentItemDetailDiscriminator)[keyof typeof ContentItemDetailDiscriminator]

export type ContentItemDetailDiscriminatorTypeMap = {
  [ContentItemDetailDiscriminator.BreakingNews]: ContentItemDetailKindBreakingNews
  [ContentItemDetailDiscriminator.ImportantNews]: ContentItemDetailKindImportantNews
}

export interface AdvertDto extends SortableItemDataAware {
  position: number
  adSlotName: string
}

export interface ContentItemDetailKind {
  id: IntegerId
  publicId: DocIdNullable
  main: boolean
  forcedSynchronicity: boolean
  teleportToIdentifier: string
  readonly discriminator: ContentItemDetailDiscriminatorType
  _system: typeof SYSTEM_CMS
}
