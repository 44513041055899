<script setup lang="ts">
import { useArticleOneStore } from '@/stores/cms/articleStore'
import ArticlePropagation from '@/views/cms/article/components/ArticlePropagation.vue'
import { storeToRefs } from 'pinia'
import type { CollabRoom, CollabStatusType } from '@anzusystems/common-admin'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const articleOneStore = useArticleOneStore()
const { article } = storeToRefs(articleOneStore)
</script>

<template>
  <ArticlePropagation
    v-if="article.docId"
    :article-doc-id="article.docId"
  />
</template>
