import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindWeather } from '@/types/cms/ContentItemKind/ContentItemKindWeather'
import { WeatherVariantDefault } from '@/types/cms/WeatherVariant'
import { LanguageDefault } from '@/model/cms/valueObject/Language'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindWeatherFactory() {
  const create = (): ContentItemKindWeather => {
    return {
      ...createAbstractContentItemKind(),
      locationId: null,
      variant: WeatherVariantDefault,
      title: '',
      url: '',
      locationUrl: '',
      locationSevenDaysUrl: '',
      locationNineDaysUrl: '',
      locationFifteenDaysUrl: '',
      language: LanguageDefault,
      discriminator: ContentItemDiscriminator.Weather,
      _resourceName: 'contentItemKindWeather',
    }
  }

  return {
    create,
  }
}
