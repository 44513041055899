<script setup lang="ts">
import CachedUserChip from '@/views/cms/user/components/CachedUserChip.vue'
import { type CollabRoom, type CollabRoomInfo, useCollabRoom } from '@anzusystems/common-admin'
import { ref } from 'vue'
import { useIntervalFn } from '@vueuse/core'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'

const props = withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    refreshInterval?: number
  }>(),
  {
    refreshInterval: 10000,
  }
)

const roomInfo = ref<CollabRoomInfo | undefined>()

const { addToCachedUsers, fetchCachedUsers } = useCachedUsers()

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const { fetchRoomInfo } = useCollabRoom(props.collabRoom, true, addToCachedUsers, fetchCachedUsers as any)

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
useIntervalFn(
  async () => {
    roomInfo.value = await fetchRoomInfo(props.collabRoom)
  },
  props.refreshInterval,
  { immediate: true, immediateCallback: true }
)
</script>

<template>
  <div
    v-if="roomInfo"
    class="collab-items d-inline-block"
  >
    <CachedUserChip
      v-for="userId in roomInfo.users.slice(0, 3)"
      :id="userId"
      :key="userId"
      minimal
    />
    <span
      v-if="roomInfo.users.length > 3"
      class="ml-1"
    >...</span>
  </div>
</template>

<style lang="scss">
.collab-items {
  &:not(:empty) {
    margin: 2px 0;
  }
}
</style>
