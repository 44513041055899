import ListView from '@/views/cms/notification/NotificationListView.vue'
import EditView from '@/views/cms/notification/NotificationEditView.vue'
import CreateView from '@/views/cms/notification/NotificationCreateView.vue'
import DetailView from '@/views/cms/notification/NotificationDetailView.vue'
import { ROUTE } from '@/router/routes'
import { ACL } from '@/composables/auth/auth'
import type { RouteRecordRaw } from 'vue-router'
import { AEmptyRouterView } from '@anzusystems/common-admin'

export const cmsNotificationRoutes: RouteRecordRaw[] = [
  {
    path: '/notification',
    component: AEmptyRouterView,
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
      breadcrumbT: 'breadcrumb.cms.notification.list',
    },
    children: [
      {
        path: '',
        name: ROUTE.CMS.NOTIFICATION.LIST,
        component: ListView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [],
          layout: 'AppLayoutDrawer',
        },
      },
      {
        path: 'create',
        name: ROUTE.CMS.NOTIFICATION.CREATE,
        component: CreateView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NOTIFICATION_CREATE],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.notification.create',
        },
      },
      {
        path: ':id(\\d+)/edit',
        name: ROUTE.CMS.NOTIFICATION.EDIT,
        component: EditView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NOTIFICATION_READ, ACL.CMS_NOTIFICATION_UPDATE],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.notification.edit',
        },
      },
      {
        path: ':id(\\d+)',
        name: ROUTE.CMS.NOTIFICATION.DETAIL,
        component: DetailView,
        meta: {
          requiresAuth: true,
          requiredPermissions: [ACL.CMS_NOTIFICATION_READ],
          layout: 'AppLayoutDrawer',
          breadcrumbT: 'breadcrumb.cms.notification.detail',
        },
      },
    ],
  },
]
