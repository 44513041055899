<script setup lang="ts">
import { useArticleIcon } from '@/model/cms/valueObject/ArticleIcon'
import { useArticleOneStore } from '@/stores/cms/articleStore'
import { WebEntityType } from '@/types/cms/TargetPosition'
import type { CollabRoom } from '@anzusystems/common-admin'
import { AFormValueObjectOptionsSelect, ARow, type CollabStatusType, useCollabHelpers } from '@anzusystems/common-admin'
import { useArticleUpdateValidation } from '@/views/cms/article/composables/articleValidations'
import ArticleIntentionRemoteAutocomplete from '@/views/cms/articleIntention/components/ArticleIntentionRemoteAutocomplete.vue'
import DesignSettingsRemoteAutocomplete from '@/views/cms/designSettings/components/DesignSettingsRemoteAutocomplete.vue'
import CachedDeskChip from '@/views/cms/desk/components/CachedDeskChip.vue'
import LayoutTemplateRemoteAutocomplete from '@/views/cms/layoutTemplate/components/LayoutTemplateRemoteAutocomplete.vue'
import RubricRemoteAutocomplete from '@/views/cms/rubric/components/RubricRemoteAutocomplete.vue'
import SiteRemoteAutocomplete from '@/views/cms/site/components/SiteRemoteAutocomplete.vue'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import UserRemoteAutocomplete from '@/views/cms/user/components/UserRemoteAutocomplete.vue'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()

const { createCollabFieldConfig } = useCollabHelpers()

const articleOneStore = useArticleOneStore()
const { article } = storeToRefs(articleOneStore)

const { cachedUsers } = useCachedUsers()

const { v$ } = useArticleUpdateValidation(article)

const { articleIconOptions } = useArticleIcon()
</script>

<template>
  <ARow>
    <SiteRemoteAutocomplete
      v-model="article.site"
      :v="v$.article.site"
      :site-group-id="article.siteGroup"
      :collab="createCollabFieldConfig('site', collabRoom, cachedUsers)"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow>
    <RubricRemoteAutocomplete
      v-model="article.rubric"
      :v="v$.article.rubric"
      required
      :label="t('cms.articleKind.widget.baseSettings.mainRubric')"
      :site-id="article.site"
      :collab="createCollabFieldConfig('rubric', collabRoom, cachedUsers)"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow>
    <ArticleIntentionRemoteAutocomplete
      v-model="article.intention"
      :v="v$.article.intention"
      required
      :collab="createCollabFieldConfig('intention', collabRoom, cachedUsers)"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow>
    <LayoutTemplateRemoteAutocomplete
      v-model="article.layoutTemplate"
      :v="v$.article.layoutTemplate"
      :web-entity-type="WebEntityType.Article"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow>
    <AFormValueObjectOptionsSelect
      v-model="article.attributesStandard.icon"
      :items="articleIconOptions"
      :label="t('cms.articleKind.model.attributesStandard.icon')"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow>
    <DesignSettingsRemoteAutocomplete
      v-model="article.designSettings"
      :v="v$.article.designSettings"
      :disabled="readonly"
      only-article-usable
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
  <ARow :title="t('cms.articleKind.model.desk')">
    <CachedDeskChip :id="article.desk" />
  </ARow>
  <ARow>
    <UserRemoteAutocomplete
      v-model="article.owners"
      multiple
      :label="t('cms.articleKind.model.owners')"
      :disabled="readonly"
      class="v-input--disabled-readonly-custom"
    />
  </ARow>
</template>
