import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth.ts'

export const extSystemMapKindAuthorRoutes: RouteRecordRaw[] = [
  {
    path: '/ext-system-map-kind-author',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_AUTHOR.LIST,
    component: () => import('@/views/cms/extSystemMapKindAuthor/ExtSystemMapKindListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-author/create',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_AUTHOR.CREATE,
    component: () => import('@/views/cms/extSystemMapKindAuthor/ExtSystemMapKindAuthorCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-author/:id(\\d+)/edit',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_AUTHOR.EDIT,
    component: () => import('@/views/cms/extSystemMapKindAuthor/ExtSystemMapKindAuthorEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_READ, ACL.CMS_EXT_SYSTEM_MAP_KIND_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-author/:id(\\d+)',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_AUTHOR.DETAIL,
    component: () => import('@/views/cms/extSystemMapKindAuthor/ExtSystemMapKindAuthorDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
