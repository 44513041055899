<script setup lang="ts">
import { ABtnSplit, type IntegerId, isUndefined } from '@anzusystems/common-admin'
import { computed } from 'vue'
import { useStages } from '@/views/cms/stage/composables/stagesAll'
import { useArticleOneStore } from '@/stores/cms/articleStore'
import { storeToRefs } from 'pinia'
import { useArticleStageCreateDtoFactory } from '@/model/cms/factory/ArticleStageFactory'
import { ArticleStagePriority } from '@/model/cms/valueObject/ArticleStagePriority'
import {
  useArticleStageCompleteActions,
  useArticleStageCreateActions,
} from '@/views/cms/articleStage/composables/articleStageActions'
import { useI18n } from 'vue-i18n'
import { ACL, useAuth } from '@/composables/auth/auth'
import type { User } from '@/types/cms/User'

const props = withDefaults(
  defineProps<{
    stageId: IntegerId
  }>(),
  {}
)

const emit = defineEmits<{
  (e: 'stageActive', data: IntegerId): void
  (e: 'stageCompleted', data: IntegerId): void
}>()
const articleOneStore = useArticleOneStore()
const { article } = storeToRefs(articleOneStore)

const { getStage } = useStages()

const stageComputed = computed(() => getStage(props.stageId))
const allowRequestAction = computed(() => {
  if (isUndefined(stageComputed.value)) {
    return undefined
  }

  return !article.value.activeStages.includes(stageComputed.value.id)
})

const { createDto, completeDto } = useArticleStageCreateDtoFactory()
const { create, stageActionsLoading, stageCreating } = useArticleStageCreateActions()
const { complete, stageCompleting } = useArticleStageCompleteActions()

const onCreateNormalPriority = () => {
  if (isUndefined(stageComputed.value)) {
    return
  }
  const dto = createDto(stageComputed.value, article.value, ArticleStagePriority.Normal)
  create(dto, () => emit('stageActive', dto.stage))
}

const onCreateHighPriority = () => {
  if (isUndefined(stageComputed.value)) {
    return
  }
  const dto = createDto(stageComputed.value, article.value, ArticleStagePriority.High)
  create(dto, () => emit('stageActive', dto.stage))
}

const onCompleteStage = () => {
  if (isUndefined(stageComputed.value)) {
    return
  }

  const dto = completeDto(stageComputed.value, article.value)
  complete(dto, () => emit('stageCompleted', dto.stage))
}

const { t } = useI18n()
const { can, useCurrentUser } = useAuth()
const { currentUser, isSuperAdmin } = useCurrentUser<User>('cms')

const canCompleteStage = computed(() => {
  // user needs to have ACL, access to desk as editor and access to stage
  return (
    can(ACL.CMS_ARTICLE_STAGE_COMPLETE) &&
    (isSuperAdmin.value ||
      (currentUser.value?.editingDesks.includes(article.value?.desk) &&
        currentUser.value?.editingStages.includes(stageComputed.value?.id ?? 0)))
  )
})
</script>

<template>
  <ABtnSplit
    v-if="stageComputed && allowRequestAction && can(ACL.CMS_ARTICLE_STAGE_CREATE)"
    class="mx-1"
    variant="tertiary"
    :disabled="stageActionsLoading"
    :loading="stageCreating"
    size="small"
    @on-click="onCreateNormalPriority"
  >
    <template #button-content>
      {{ stageComputed.activeName }}
    </template>
    <VListItem @click.stop="onCreateHighPriority">
      {{ t('cms.articleStage.action.createWithHighPriority', { actionName: stageComputed.activeName }) }}
    </VListItem>
  </ABtnSplit>
  <ABtnSecondary
    v-if="stageComputed && !allowRequestAction && canCompleteStage"
    rounded="pill"
    class="mx-1"
    :disabled="stageActionsLoading"
    :loading="stageCompleting"
    @click.stop="onCompleteStage"
  >
    {{ stageComputed.solvedName }}
  </ABtnSecondary>
</template>
