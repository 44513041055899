import type { DesignSettings } from '@/types/cms/DesignSettings'
import { useValidate } from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'

export const DesignSettingsValidationSymbol = Symbol.for('anzu:cms:design-settings-validation-scope')

export function useDesignSettingsValidation(designSettings: Ref<DesignSettings>) {
  const { maxLength, minLength, required } = useValidate()

  const rules = {
    designSettings: {
      texts: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        description: {
          maxLength: maxLength(2000),
        },
      },
      attributes: {
        theme: {
          required,
        },
        brickProjectName: {
          maxLength: maxLength(100),
        },
        favicon: {
          maxLength: maxLength(100),
        },
        articleUsable: {},
      },
      header: {
        template: {
          required,
        },
      },
    },
  }
  const v$ = useVuelidate(rules, { designSettings }, { $scope: DesignSettingsValidationSymbol })

  return {
    v$,
  }
}
