import { ref } from 'vue'
import type { Notification } from '@/types/cms/Notification'
import type { FilterBag, IntegerId, Pagination, ValueObjectOption } from '@anzusystems/common-admin'
import { useAlerts } from '@anzusystems/common-admin'
import {
  createNotification, deleteNotification,
  fetchNotification,
  fetchNotificationList,
  fetchNotificationListByIds, sendNotification,
  updateNotification,
} from '@/services/api/cms/notificationApi'
import { useNotificationOneStore } from '@/stores/cms/notificationStore'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { ROUTE } from '@/router/routes'
import useVuelidate from '@vuelidate/core'
import { useCachedSites } from '@/views/cms/site/composables/cachedSites'
import { NotificationValidationSymbol } from '@/views/cms/notification/composables/notificationValidation'

const { showValidationError, showRecordWas, showErrorsDefault, showSuccessT } = useAlerts()
const { addToCachedSites, fetchCachedSites } = useCachedSites()

const datatableHiddenColumns = ref<Array<string>>([])
const listLoading = ref(false)
const detailLoading = ref(false)
const saveButtonLoading = ref(false)

export const useNotificationSelectActions = () => {
  const fetchItems = async (pagination: Pagination, filterBag: FilterBag) => {
    const notificationList = await fetchNotificationList(pagination, filterBag)

    return <ValueObjectOption<IntegerId>[]>notificationList.map((notification: Notification) => ({
      title: notification.texts.headline,
      value: notification.id,
    }))
  }

  const fetchItemsByIds = async (ids: number[]) => {
    const notificationList = await fetchNotificationListByIds(ids)
    return <ValueObjectOption<IntegerId>[]>notificationList.map((notification: Notification) => ({
      value: notification.id,
      title: notification.texts.headline,
    }))
  }

  return {
    fetchItems,
    fetchItemsByIds,
  }
}

export const useNotificationDetailActions = () => {
  const notificationOneStore = useNotificationOneStore()
  const { notification, notificationLoaded } = storeToRefs(notificationOneStore)

  const fetchData = async (id: number) => {
    detailLoading.value = true
    try {
      const notificationRes = await fetchNotification(id)
      notification.value = notificationRes
      addToCachedSites(notificationRes.site)
      await fetchCachedSites()
      notificationLoaded.value = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    notification,
    detailLoading,
    fetchData,
    resetStore: notificationOneStore.reset,
  }
}

export const useNotificationDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: number) => {
    try {
      await deleteNotification(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.NOTIFICATION.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}

export const useNotificationCreateEditActions = () => {
  const v$ = useVuelidate({ $scope: NotificationValidationSymbol })
  const router = useRouter()
  const notificationOneStore = useNotificationOneStore()
  const { notification, notificationLoaded } = storeToRefs(notificationOneStore)

  const fetchData = async (id: number) => {
    detailLoading.value = true
    try {
      notification.value = await fetchNotification(id)
      notificationLoaded.value = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async (close = false) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      await updateNotification(notificationOneStore.notification.id, notificationOneStore.notification)
      showRecordWas('updated')
      if (!close) return
      await router.push({ name: ROUTE.CMS.NOTIFICATION.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onUpdateAndSend = async () => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      await updateNotification(notificationOneStore.notification.id, notificationOneStore.notification)
      await sendNotification(notificationOneStore.notification.id)
      showSuccessT('cms.notification.action.sentSuccess')
      await router.push({ name: ROUTE.CMS.NOTIFICATION.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  const onCreate = async (close = false) => {
    try {
      saveButtonLoading.value = true
      v$.value.$touch()
      if (v$.value.$invalid) {
        showValidationError()
        saveButtonLoading.value = false
        return
      }
      const notificationRes = await createNotification(notificationOneStore.notification)
      showRecordWas('created')
      if (close) {
        await router.push({ name: ROUTE.CMS.NOTIFICATION.LIST })
        return
      }
      await router.push({ name: ROUTE.CMS.NOTIFICATION.DETAIL, params: { id: notificationRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      saveButtonLoading.value = false
    }
  }

  return {
    detailLoading,
    saveButtonLoading,
    notification,
    notificationLoaded,
    fetchData,
    onUpdate,
    onCreate,
    onUpdateAndSend,
    resetStore: notificationOneStore.reset,
  }
}

export const useNotificationListActions = () => {
  const listItems = ref<Notification[]>([])
  const selectedNotificationList = ref<Notification[]>([])

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      listItems.value = await fetchNotificationList(pagination, filterBag)
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
    selectedNotificationList,
  }
}
