<script setup lang="ts">
import { useArticleOneStore } from '@/stores/cms/articleStore'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import {
  ACollabLockedByUser,
  ARow,
  ASortable,
  cloneDeep,
  type CollabFieldData,
  CollabFieldLockStatus,
  type CollabFieldLockStatusPayload,
  CollabFieldLockType,
  type CollabRoom,
  type CollabStatusType,
  type IntegerIdNullable,
  isUndefined,
  type SortableItem,
  useAlerts,
  useCollabAnyDataChange,
  useCollabField,
  useCommonAdminCollabOptions,
} from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useThirdPartyTrackerFactory } from '@/model/cms/factory/ThirdPartyTrackerFactory'
import {
  type ThirdPartyTrackerDto,
  ThirdPartyTrackerService,
  type ThirdPartyTrackerServiceType,
  type ThirdPartyTrackerUpsertDto,
} from '@/types/cms/ThirdPartyTracker'
import ThirdPartyTrackerItemForm from '@/views/cms/articleThirdPartyTracker/components/ThirdPartyTrackerItemForm.vue'
import ThirdPartyTrackerServiceChip from '@/views/cms/articleThirdPartyTracker/components/ThirdPartyTrackerServiceChip.vue'
import { upsertArticleThirdPartyTrackers } from '@/services/api/cms/articleApi'
import useVuelidate from '@vuelidate/core'
import { ArticleThirdPartyTrackerValidationScope } from '@/views/cms/article/composables/articleValidations'
import { COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE } from '@/views/cms/article/composables/articleActions'

const props = withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const articleOneStore = useArticleOneStore()
const { article } = storeToRefs(articleOneStore)

const { createThirdPartyTrackerUpsertDto, createThirdPartyTrackerDto } = useThirdPartyTrackerFactory()

const loading = ref(false)
const edit = ref(false)
const thirdPartyTrackersUpsertDto = ref<ThirdPartyTrackerUpsertDto>(
  createThirdPartyTrackerUpsertDto(cloneDeep(article.value.thirdPartyTrackers))
)
const serviceValues = Object.values(ThirdPartyTrackerService)

const { showErrorsDefault, showValidationError, showRecordWas } = useAlerts()

const onAddThirdPartyTracker = (beforeSortableItem: ThirdPartyTrackerDto | null) => {
  if (serviceValues.length === thirdPartyTrackersUpsertDto.value.thirdPartyTrackers.length) {
    return
  }
  const newThirdPartyTracker = createThirdPartyTrackerDto()
  const usableServices = serviceValues.filter((service) => {
    return isUndefined(
      thirdPartyTrackersUpsertDto.value.thirdPartyTrackers.find(
        (thirdPartyTracker) => thirdPartyTracker.service === service
      )
    )
  })

  newThirdPartyTracker.position = (beforeSortableItem?.position ?? 0) + 1
  newThirdPartyTracker.service = usableServices[0]
  newThirdPartyTracker.new = true
  thirdPartyTrackersUpsertDto.value.thirdPartyTrackers.push(newThirdPartyTracker)
}
const onDeleteThirdPartyTracker = (item: SortableItem) => {
  thirdPartyTrackersUpsertDto.value.thirdPartyTrackers.splice(item.index, 1)
}

const v$ = useVuelidate({ $scope: ArticleThirdPartyTrackerValidationScope })

const { cachedUsers } = useCachedUsers()
const { collabOptions } = useCommonAdminCollabOptions()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const releaseFieldLock = ref((data: CollabFieldData) => {})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeFieldData = ref((data: CollabFieldData) => {})
const acquireFieldLock = ref(() => {})
const lockedByUserLocal = ref<IntegerIdNullable>(null)
if (collabOptions.value.enabled) {
  const {
    releaseCollabFieldLock,
    changeCollabFieldData,
    acquireCollabFieldLock,
    lockedByUser,
    addCollabFieldLockStatusListener,
    addCollabGatheringBufferDataListener,
  } = useCollabField(props.collabRoom, 'thirdPartyTrackers')
  releaseFieldLock.value = releaseCollabFieldLock
  changeFieldData.value = changeCollabFieldData
  acquireFieldLock.value = acquireCollabFieldLock
  watch(
    lockedByUser,
    (newValue) => {
      lockedByUserLocal.value = newValue
    },
    { immediate: true }
  )
  addCollabFieldLockStatusListener((data: CollabFieldLockStatusPayload) => {
    if (data.status === CollabFieldLockStatus.Failure && data.type === CollabFieldLockType.Acquire) {
      edit.value = false
    }
  })
  addCollabGatheringBufferDataListener(() => {
    edit.value = false
  })
}

const save = async () => {
  loading.value = true
  v$.value.$touch()
  if (v$.value.$invalid) {
    showValidationError()
    loading.value = false
    return
  }
  try {
    const newArticle = await upsertArticleThirdPartyTrackers(article.value.id, thirdPartyTrackersUpsertDto.value)
    article.value.thirdPartyTrackers = cloneDeep(newArticle.thirdPartyTrackers)
    article.value.lockingVersion = newArticle.lockingVersion
    thirdPartyTrackersUpsertDto.value = createThirdPartyTrackerUpsertDto(cloneDeep(newArticle.thirdPartyTrackers))
    const { changeCollabAnyData } = useCollabAnyDataChange(props.collabRoom)
    changeCollabAnyData(COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE, newArticle.lockingVersion)
    releaseFieldLock.value(article.value.thirdPartyTrackers)
    showRecordWas('updated')
    edit.value = false
  } catch (error) {
    showErrorsDefault(error)
  } finally {
    loading.value = false
  }
}

const onEdit = () => {
  acquireFieldLock.value()
  edit.value = true
}

const onCancel = () => {
  thirdPartyTrackersUpsertDto.value = createThirdPartyTrackerUpsertDto(cloneDeep(article.value.thirdPartyTrackers))
  releaseFieldLock.value(article.value.thirdPartyTrackers)
  edit.value = false
}

const finalCode = (service: ThirdPartyTrackerServiceType, param: string) => {
  if (service === ThirdPartyTrackerService.Gemius) {
    return `<img src="https://sk.hit.gemius.pl/${param}"/>`
  }
  if (service === ThirdPartyTrackerService.AdForm) {
    return `<img src="https://track.adform.net/${param}" style="display:none;"/>`
  }
  if (service === ThirdPartyTrackerService.DoubleClick) {
    return `<img src="https://ad.doubleclick.net/${param}" attributionsrc border="0" height="1" width="1" alt="Advertisement"/>`
  }
  return `<img src="https://cm1.jhmt.cz/${param}" style="display:none;" />`
}

const { t } = useI18n()
</script>

<template>
  <div>
    <div v-if="edit">
      <ARow>
        <ASortable
          v-model="thirdPartyTrackersUpsertDto.thirdPartyTrackers"
          :show-add-last-button="
            Object.values(ThirdPartyTrackerService).length !== thirdPartyTrackersUpsertDto.thirdPartyTrackers.length
          "
          show-delete-button
          disable-draggable
          add-last-button-t="cms.articleKind.widget.thirdPartyTrackers.add"
          class="a-sortable-widget--third-party-tracker"
          @on-add-last="onAddThirdPartyTracker"
          @on-delete="onDeleteThirdPartyTracker"
        >
          <template #item="{ item }: { item: SortableItem<ThirdPartyTrackerDto> }">
            <ThirdPartyTrackerItemForm
              v-model="item.raw"
              :is-moderator="isModerator"
              :collab-room="collabRoom"
              :readonly="readonly || !item.raw.new"
            />
          </template>
        </ASortable>
      </ARow>
      <ARow>
        <div class="d-flex justify-end align-center">
          <ABtnSecondary
            class="right-0"
            :loading="loading"
            @click="save"
          >
            {{ t('common.button.save') }}
          </ABtnSecondary>
          <ABtnTertiary
            class="ml-2"
            size="small"
            :disabled="loading"
            @click="onCancel"
          >
            {{ t('common.button.cancel') }}
          </ABtnTertiary>
        </div>
      </ARow>
    </div>
    <div v-else>
      <VList item-props>
        <VListItem
          v-for="thirdPartyTracker in article.thirdPartyTrackers"
          :key="thirdPartyTracker.id"
          class="mb-2"
        >
          <template #title>
            <ThirdPartyTrackerServiceChip
              :service="thirdPartyTracker.service"
              class="mb-2"
            />
          </template>
          <span class="text-caption">
            <span class="font-weight-bold">{{ t('cms.thirdPartyTracker.meta.finalCode') }}:</span>
            {{ finalCode(thirdPartyTracker.service, thirdPartyTracker.param) }}
          </span>
        </VListItem>
      </VList>
      <div
        v-if="!readonly"
        class="d-flex justify-end"
      >
        <ACollabLockedByUser
          v-if="lockedByUserLocal"
          :id="lockedByUserLocal"
          :users="cachedUsers"
        />
        <VBtn
          v-else
          color="grey-lighten-1"
          icon="mdi-pencil"
          variant="text"
          size="small"
          @click="onEdit"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use 'sass:color';

$bg-color: #fff;
$border-color: lightgray;
$class-name-root: 'a-sortable-widget';
$border-color-hover: gray;

.a-sortable-widget--third-party-tracker {
  .#{$class-name-root} {
    &__item {
      border-bottom: 1px solid $border-color !important;
      margin-bottom: 10px;
      padding-left: 8px;

      &:hover {
        border-color: $border-color-hover !important;
      }

      &:hover + & {
        border-top: 1px solid $border-color-hover;
      }
    }

    &__handle {
      display: none;
    }

    &__buttons {
      margin-left: 16px;
      padding-bottom: 6px;
    }
  }
}
</style>
