import { reactive } from 'vue'
import { makeFilterHelper } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { ENTITY } from '@/services/api/cms/notificationApi'

const makeFilter = makeFilterHelper(SYSTEM_CMS, ENTITY)

const filter = reactive({
  id: {
    ...makeFilter({ name: 'id', variant: 'in' }),
  },
  headline: {
    ...makeFilter({ name: 'headline', variant: 'contains', field: 'texts.headline' }),
  },
  articleDocId: {
    ...makeFilter({ name: 'articleDocId', advanced: true }),
  },
})

export function useNotificationListFilter() {
  return filter
}
