import type { Article } from '@/types/cms/Article/Article'
import type { ArticleCreateDto } from '@/types/cms/Article/ArticleCreateDto'
import { dateTimePretty, dateToUtc, useI18n, useValidate } from '@anzusystems/common-admin'
import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { computed, ref } from 'vue'
import type { ArticlePublicPreview } from '@/types/cms/ArticlePublicPreview'

export const ArticleValidationSymbol = Symbol.for('anzu:cms:article-validation-scope')
export const ArticleCustomDataValidationScope = Symbol.for('anzu:cms:article-custom-data-validation-scope')
export const ArticlePublicPreviewValidationScope = Symbol.for('anzu:cms:article-public-preview-validation-scope')
export const ArticleThirdPartyTrackerValidationScope = Symbol.for(
  'anzu:cms:article-third-party-tracker-validation-scope'
)

export function useArticleUpdateValidation(article: Ref<Article>) {
  const { t } = useI18n()
  const { maxLength, minLength, minValue, required, slug, datesCompare } = useValidate()

  const publicPublishedAtComputed = computed(() => {
    return article.value.dates.publicPublishedAt
  })

  const publicUpdatedAtComputed = computed(() => {
    return article.value.dates.publicUpdatedAt
  })

  const rules = {
    article: {
      texts: {
        headline: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        overline: {
          maxLength: maxLength(255),
        },
        perex: {
          maxLength: maxLength(4096),
        },
      },
      seo: {
        description: {
          maxLength: maxLength(4096),
        },
        title: {
          maxLength: maxLength(255),
        },
        slug: {
          maxLength: maxLength(255),
          slug,
        },
      },
      canonicalUrl: {
        maxLength: maxLength(4096),
      },
      keywords: {},
      site: {
        required,
        minValue: minValue(1),
      },
      siteGroup: {
        required,
        minValue: minValue(1),
      },
      rubric: {
        required,
        minValue: minValue(1),
      },
      intention: {
        required,
        minValue: minValue(1),
      },
      layoutTemplate: {
        required,
        minValue: minValue(1),
      },
      designSettings: {},
      dates: {
        publishedAt: {
          datesCompare: datesCompare(
            publicPublishedAtComputed,
            t('cms.articleKind.model.dates.publicPublishedAt'),
            'onOrAfter'
          ),
        },
        publicPublishedAt: {
          datesCompare: datesCompare(
            publicUpdatedAtComputed,
            t('cms.articleKind.model.dates.publicUpdatedAt'),
            'earlierThan'
          ),
        },
        publicUpdatedAt: {
          datesCompare: datesCompare(
            publicPublishedAtComputed,
            t('cms.articleKind.model.dates.publicPublishedAt'),
            'laterThan'
          ),
        },
      },
    },
  }

  const v$ = useVuelidate(rules, { article }, { $scope: ArticleValidationSymbol })

  return {
    v$,
  }
}

export function useArticleCreateValidation(article: Ref<ArticleCreateDto>) {
  const { maxLength, minLength, minValue, required } = useValidate()
  const rules = {
    article: {
      headline: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      desk: {
        required,
        minValue: minValue(1),
      },
      rubric: {
        required,
        minValue: minValue(1),
      },
      intention: {
        required,
        minValue: minValue(1),
      },
    },
  }

  const v$ = useVuelidate(rules, { article }, { $scope: ArticleValidationSymbol })

  return {
    v$,
  }
}

export function useArticlePublicPreviewValidation(articlePublicPreview: Ref<ArticlePublicPreview>) {
  const { datesCompare } = useValidate()
  const now = new Date()
  const nowRef = ref(dateToUtc(now))
  const nowPlusWeekRef = ref(dateToUtc(now.setDate(now.getDate() + 7)))

  const rules = {
    articlePublicPreview: {
      expiresAt: {
        // eslint-disable-next-line vue/no-ref-object-reactivity-loss
        onOrBefore: datesCompare(nowPlusWeekRef, dateTimePretty(nowPlusWeekRef.value), 'onOrBefore'),
        // eslint-disable-next-line vue/no-ref-object-reactivity-loss
        onOrAfter: datesCompare(nowRef, dateTimePretty(nowRef.value), 'onOrAfter'),
      },
    },
  }

  const v$ = useVuelidate(rules, { articlePublicPreview }, { $scope: ArticlePublicPreviewValidationScope })

  const minDatePicker = new Date(new Date().setDate(new Date().getDate() - 1))
  const maxDatePicker = new Date(new Date().setDate(new Date().getDate() + 8))

  return {
    v$,
    minDatePicker,
    maxDatePicker,
  }
}
