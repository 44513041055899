import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

import { type EmbedWeatherAware, type EmbedWeatherCreateUpdateDto } from '@/components/anzutap/types/EmbedWeather'
import { WeatherVariantDefault } from '@/types/cms/WeatherVariant'
import { LanguageDefault } from '@/model/cms/valueObject/Language'

export function useEmbedWeatherFactory() {
  const createDefault = (id = ''): EmbedWeatherAware => {
    return {
      id,
      locationId: null,
      locationUrl: '',
      locationSevenDaysUrl: '',
      locationNineDaysUrl: '',
      locationFifteenDaysUrl: '',
      language: LanguageDefault,
      title: '',
      variant: WeatherVariantDefault,
      customData: {},
      discriminator: 'weather',
      _resourceName: 'embedKindWeather',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedWeatherCreateUpdateDto => {
    return {
      id,
      locationId: null,
      title: '',
      locationUrl: '',
      locationSevenDaysUrl: '',
      locationNineDaysUrl: '',
      locationFifteenDaysUrl: '',
      language: LanguageDefault,
      variant: WeatherVariantDefault,
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
