import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useAppNotificationStore } from '@/composables/appNotification/appNotificationStore'

export const useMinuteStore = defineStore('cmsMinuteStore', () => {
  const minuteSidebar = ref(false)

  const appNotificationStore = useAppNotificationStore()
  const { appNotificationSidebar } = storeToRefs(appNotificationStore)

  function toggleMinuteSidebar() {
    if (!minuteSidebar.value && appNotificationSidebar.value) {
      minuteSidebar.value = true
      appNotificationSidebar.value = false
      return
    }
    minuteSidebar.value = !minuteSidebar.value
  }

  function reset() {
    minuteSidebar.value = false
  }

  return {
    minuteSidebar,
    toggleMinuteSidebar,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMinuteStore, import.meta.hot))
}
