import { useArticleFactory } from '@/model/cms/factory/Article/ArticleFactory'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { ArticleRelatedEntities } from '@/types/cms/Article/Article'

export const useArticleRelatedEntitiesOneStore = defineStore('cmsArticleRelatedEntitiesOneStore', () => {
  const { createArticleRelatedEntities } = useArticleFactory()

  const relatedEntitiesLoading = ref(false)
  const articleRelatedEntities = ref<ArticleRelatedEntities>(createArticleRelatedEntities())

  function reset() {
    articleRelatedEntities.value = createArticleRelatedEntities()
  }

  return {
    relatedEntitiesLoading,
    articleRelatedEntities,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticleRelatedEntitiesOneStore, import.meta.hot))
}
