import type { Notification } from '@/types/cms/Notification'
import { NotificationFamilyDefault } from '@/model/cms/valueObject/NotificationFamily.ts'
import { dateTimeNow } from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { NotificationPriorityDefault } from '@/model/cms/valueObject/NotificationPriority.ts'
import { NotificationDestinationDefault } from '@/model/cms/valueObject/NotificationDestination.ts'

export function useNotificationFactory() {
  const createDefaultNotification = (): Notification => {
    return {
      id: 0,
      articleDocId: '',
      url: null,
      image: null,
      texts: {
        headline: '',
        overline: '',
        summary: '',
      },
      family: NotificationFamilyDefault,
      priority: NotificationPriorityDefault,
      destination: NotificationDestinationDefault,
      site: 0,
      siteGroup: 0,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      sentAt: null,
      sentBy: null,
      _resourceName: 'notification',
      _system: SYSTEM_CMS,
    }
  }

  return {
    createDefaultNotification,
  }
}
