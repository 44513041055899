import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth.ts'

export const extSystemMapKindRubricRoutes: RouteRecordRaw[] = [
  {
    path: '/ext-system-map-kind-rubric',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_RUBRIC.LIST,
    component: () => import('@/views/cms/extSystemMapKindRubric/ExtSystemMapKindListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-rubric/create',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_RUBRIC.CREATE,
    component: () => import('@/views/cms/extSystemMapKindRubric/ExtSystemMapKindRubricCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-rubric/:id(\\d+)/edit',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_RUBRIC.EDIT,
    component: () => import('@/views/cms/extSystemMapKindRubric/ExtSystemMapKindRubricEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_READ, ACL.CMS_EXT_SYSTEM_MAP_KIND_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-map-kind-rubric/:id(\\d+)',
    name: ROUTE.CMS.EXT_SYSTEM_MAP_KIND_RUBRIC.DETAIL,
    component: () => import('@/views/cms/extSystemMapKindRubric/ExtSystemMapKindRubricDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_MAP_KIND_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
