import type { ValueObjectOption } from '@anzusystems/common-admin'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export const ArticleLockType = {
  Free: 'free',
  Locked: 'locked',
} as const
export type ArticleLockTypeType = (typeof ArticleLockType)[keyof typeof ArticleLockType]
export const ArticleLockTypeDefault = ArticleLockType.Free

export function useArticleLockType() {
  const { t } = useI18n()

  const articleLockTypeOptions = ref<ValueObjectOption<ArticleLockTypeType>[]>([
    {
      value: ArticleLockType.Free,
      title: t('cms.articleKind.articleLockType.free'),
    },
    {
      value: ArticleLockType.Locked,
      title: t('cms.articleKind.articleLockType.locked'),
    },
  ])

  const getArticleLockTypeOption = (value: ArticleLockTypeType) => {
    return articleLockTypeOptions.value.find((item) => item.value === value)
  }

  return {
    articleLockTypeOptions,
    getArticleLockTypeOption,
  }
}
