import { isUndefined } from '@anzusystems/common-admin'

export const WeatherVariant = {
  Day: 'day',
  ThreeDay: 'threeDay',
  Week: 'week',
} as const
export const WeatherVariantDefault = WeatherVariant.ThreeDay
export type WeatherVariantType = (typeof WeatherVariant)[keyof typeof WeatherVariant]

export const getResolvedNumberOfDaysByWeatherVariant = (weatherVariant: WeatherVariantType | undefined) =>
{
  if (isUndefined((weatherVariant))) {
    return 3
  }
  if (WeatherVariant.Day === weatherVariant) {
    return 1
  }
  if (WeatherVariant.ThreeDay === weatherVariant) {
    return 3
  }
  if (WeatherVariant.Week === weatherVariant) {
    return 7
  }

  return 3
}
