import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'

export const cmsArticlePurchaseAuthorRoutes: RouteRecordRaw[] = [
  {
    path: '/article-purchase-author',
    name: ROUTE.CMS.ARTICLE_PURCHASE_AUTHOR.LIST,
    component: () => import('@/views/cms/articlePurchase/ArticlePurchaseAuthorListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
