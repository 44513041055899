import { acceptHMRUpdate, defineStore } from 'pinia'
import { useNotificationFactory } from '@/model/cms/factory/NotificationFactory'
import { ref } from 'vue'

const { createDefaultNotification } = useNotificationFactory()

export const useNotificationOneStore = defineStore('cmsNotificationOneStore', () => {
  const notification = ref(createDefaultNotification())
  const notificationLoaded = ref(false)

  function reset() {
    notification.value = createDefaultNotification()
    notificationLoaded.value = false
  }

  return {
    notification,
    notificationLoaded,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationOneStore, import.meta.hot))
}
