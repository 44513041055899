import { useCookies } from '@vueuse/integrations/useCookies'
import { envConfig } from '@/services/EnvConfigService'
import { isUndefined, localTimeShiftInSeconds } from '@anzusystems/common-admin'
import { ref } from 'vue'

let cookies: undefined | ReturnType<typeof useCookies> = undefined
const cookieStateInitialized = ref(false)

export function useCookiesState() {
  const initCookieState = () => {
    cookies = useCookies([envConfig.cookies.refreshTokenExistsName, envConfig.cookies.jwtPayloadName])
    cookieStateInitialized.value = true
  }

  const getRefreshTokenExistsName = () => {
    if (isUndefined(cookies)) throw new Error("Can't use useCookiesState before initCookiesState")

    return cookies.get<string | undefined>(envConfig.cookies.refreshTokenExistsName)
  }

  const getJwtPayloadName = () => {
    if (isUndefined(cookies)) throw new Error("Can't use useCookiesState before initCookiesState")

    return cookies.get<string | undefined>(envConfig.cookies.jwtPayloadName)
  }

  const getJwtPayload = () => {
    const jwtPayload = getJwtPayloadName()
    if (isUndefined(jwtPayload)) return undefined
    try {
      return JSON.parse(atob(jwtPayload.split('.')[1]))
    } catch (e) {
      return undefined
    }
  }

  const getJwtExpire = (): number | undefined => {
    const jwtPayload = getJwtPayload()
    if (isUndefined(jwtPayload)) return undefined
    try {
      return jwtPayload.exp
    } catch (e) {
      return undefined
    }
  }

  const isJwtExpired = () => {
    const jwtExpire = getJwtExpire()
    if (isUndefined(jwtExpire)) return true

    return jwtExpire - localTimeShiftInSeconds.value < Date.now() / 1000
  }

  return {
    initCookieState,
    cookieStateInitialized,
    getRefreshTokenExistsName,
    getJwtPayloadName,
    getJwtPayload,
    getJwtExpire,
    isJwtExpired,
  }
}
