import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Article } from '@/types/cms/Article/Article'
import { ref } from 'vue'
import type { MainBarDialogType } from '@/views/cms/article/components/mainBarButtons/mainBarButtons'

export const useMainBarDialogsStore = defineStore('cmsMainBarDialogsStore', () => {
  const article = ref<Article | null>(null)
  const articleVersionData = ref<Article | null>(null)
  const mainBarDialog = ref<null | MainBarDialogType>(null)

  function openDialog(variant: MainBarDialogType) {
    mainBarDialog.value = variant
  }

  function closeDialog() {
    mainBarDialog.value = null
  }

  function reset() {
    article.value = null
    articleVersionData.value = null
    mainBarDialog.value = null
  }

  return {
    article,
    articleVersionData,
    mainBarDialog,
    openDialog,
    closeDialog,
    reset,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMainBarDialogsStore, import.meta.hot))
}
