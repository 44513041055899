<script setup lang="ts">
import { fetchArticleActiveAndReservedRouteList } from '@/services/api/cms/routeKindApi'
import { envConfig } from '@/services/EnvConfigService'
import { useArticleOneStore } from '@/stores/cms/articleStore'
import { type RouteKindBase } from '@/types/cms/RouteKind/RouteKind'
import { RouteStatus } from '@/model/cms/valueObject/RouteStatus'
import type { CollabRoom } from '@anzusystems/common-admin'
import { type CollabStatusType, type DocId, isUndefined, useAlerts } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()
const url = ref('')
const status = ref<'init' | 'loading' | 'error' | 'ready'>('init')
const { article, articleSite } = storeToRefs(useArticleOneStore())
const activeRoute = ref<RouteKindBase | undefined>(undefined)
const { showErrorsDefault } = useAlerts()

const init = async (articleDocId: DocId) => {
  status.value = 'loading'
  try {
    const res = await fetchArticleActiveAndReservedRouteList(articleDocId)
    activeRoute.value = res.data.find((item: RouteKindBase) => item.status === RouteStatus.Active)
  } catch (e) {
    showErrorsDefault(e)
    status.value = 'error'
  }
}

const prefixWithSiteDomain = (uri: string | undefined) => {
  return articleSite.value?.domain + '/' + (uri ?? '')
}

watch(
  [article, articleSite],
  async ([articleNewValue, articleSiteNewValue]) => {
    if (status.value !== 'init') return
    if (articleNewValue.docId.length === 0 || isUndefined(articleSiteNewValue)) return
    await init(articleNewValue.docId)
    if (!isUndefined(articleNewValue)) {
      url.value = prefixWithSiteDomain(activeRoute.value?.uri.full)
    }
  },
  { immediate: true }
)
</script>

<template>
  <div class="d-flex align-center justify-center">
    <ABtnTertiary
      v-if="url"
      target="_blank"
      rel="noopener noreferrer"
      size="small"
      :href="
        envConfig.mailerDomain +
          '/mailer/mail-generator/?do=sourceTemplateChange&source_template_id=7&url=' +
          encodeURIComponent(url)
      "
    >
      {{ t('cms.articleKind.action.sendNewsletter') }}
    </ABtnTertiary>
  </div>
</template>
