import { ACL, useAuth } from '@/composables/auth/auth'
import { ArticleStatus } from '@/model/cms/valueObject/ArticleStatus'
import { ROUTE } from '@/router/routes'
import type { Article } from '@/types/cms/Article/Article'
import { MainBarDialog } from '@/views/cms/article/components/mainBarButtons/mainBarButtons'
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { tryLoadLatestVersionOfArticle } from '@/services/api/cms/articleApiHelpers'

export const useMainBarDialogActions = () => {
  const mainBarDialogsStore = useMainBarDialogsStore()
  const router = useRouter()
  const { canForAll } = useAuth()

  const canEdit = computed(() => {
    return canForAll([ACL.CMS_ARTICLE_READ, ACL.CMS_ARTICLE_UPDATE])
  })

  const onRowClick = async (item: Article) => {
    if (!item.docId) return
    try {
      const res = await tryLoadLatestVersionOfArticle(item.docId)
      if (!res) return
      mainBarDialogsStore.article = res
      if (res.status === ArticleStatus.Draft) {
        router.push({
          name: canEdit.value ? ROUTE.CMS.ARTICLE.EDIT : ROUTE.CMS.ARTICLE.DETAIL,
          params: { id: res.id },
        })
        return
      }
      if (res.status === ArticleStatus.Ready) {
        mainBarDialogsStore.openDialog(MainBarDialog.EditDialogReady)
        return
      }
      if (res.status === ArticleStatus.Published) {
        mainBarDialogsStore.openDialog(MainBarDialog.EditDialogPublished)
        return
      }
    } catch (e) {
      //
    }
  }

  const onWithdraw = (item: Article) => {
    if (!item.id) return
    mainBarDialogsStore.article = item
    if (item.status === ArticleStatus.Published) {
      mainBarDialogsStore.openDialog(MainBarDialog.Withdraw)
    }
  }

  return {
    onRowClick,
    onWithdraw,
  }
}
