<script lang="ts" setup>
import {
  AActionCloseButton,
  AActionDeleteButton,
  AActionSaveButton,
  ACard,
  defineBreadcrumbs,
  stringToInt,
} from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { onBeforeUnmount, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import {
  useNotificationCreateEditActions,
  useNotificationDeleteActions,
} from '@/views/cms/notification/composables/notificationActions'
import NotificationManage from '@/views/cms/notification/components/NotificationManage.vue'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

const route = useRoute()
const id = stringToInt(route.params.id)

const { onDelete } = useNotificationDeleteActions()
const {
  detailLoading,
  fetchData,
  resetStore,
  onUpdate,
  notification,
  onUpdateAndSend
} = useNotificationCreateEditActions()

const showDialog = ref(false)

const openDialog = () => {
  showDialog.value = true
}

const closeDialog = () => {
  showDialog.value = false
}

const sendNotification = () => {
  showDialog.value = false
  onUpdateAndSend()
}

const { t } = useI18n()

const breadcrumbs = defineBreadcrumbs(
  computed(() => [
    { title: t('breadcrumb.cms.notification.list'), routeName: ROUTE.CMS.NOTIFICATION.LIST },
    {
      title: notification.value.texts.headline || t('breadcrumb.cms.notification.edit'),
      routeName: ROUTE.CMS.NOTIFICATION.EDIT,
      id,
    },
  ])
)

const getData = () => {
  fetchData(id)
}

onMounted(() => {
  getData()
})

onBeforeUnmount(() => {
  resetStore()
})
</script>

<template>
  <ActionbarWrapper :breadcrumbs="breadcrumbs">
    <template #buttons>
      <ABtnPrimary
        v-if="!detailLoading && notification.sentAt === null"
        rounded="pill"
        class="mx-1"
        @click.stop="openDialog"
      >
        {{ t('cms.notification.action.send') }}
      </ABtnPrimary>
      <ConfirmDialog
        :message="t('cms.notification.confirm.message')"
        :show="showDialog"
        :confirm-action-title="t('cms.notification.confirm.send')"
        @confirm="sendNotification"
        @cancel="closeDialog"
      />
      <AActionSaveButton
        v-if="!detailLoading && notification.sentAt === null"
        variant="secondary"
        @save-record="onUpdate"
      />
      <AActionDeleteButton
        v-if="!detailLoading && notification.sentAt === null"
        @delete-record="onDelete(id)"
      />
      <AActionCloseButton :route-name="ROUTE.CMS.NOTIFICATION.LIST" />
    </template>
  </ActionbarWrapper>

  <ACard :loading="detailLoading">
    <VCardText>
      <NotificationManage is-edit />
    </VCardText>
  </ACard>
</template>
