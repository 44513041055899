import useVuelidate from '@vuelidate/core'
import type { Ref } from 'vue'
import { computed } from 'vue'
import { useValidate } from '@anzusystems/common-admin'
import { ArticleThirdPartyTrackerValidationScope } from '@/views/cms/article/composables/articleValidations'
import type { ThirdPartyTrackerDto } from '@/types/cms/ThirdPartyTracker'

export function useArticleThirdPartyTrackerValidation(thirdPartyTracker: Ref<ThirdPartyTrackerDto>) {
  const { minLength, required } = useValidate()

  const rules = computed(() => {
    return {
      thirdPartyTracker: {
        service: {
          required,
        },
        param: {
          required,
          minLength: minLength(3),
        },
      },
    }
  })
  const v$ = useVuelidate(rules, { thirdPartyTracker }, { $scope: ArticleThirdPartyTrackerValidationScope })

  return {
    v$,
  }
}
