<script lang="ts" setup>
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'
import { useArticleEditActions } from '@/views/cms/article/composables/articleActions'
import { ADialogToolbar, useCollabHelpers, useCollabRoom } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const mainBarDialogsStore = useMainBarDialogsStore()
const { article, saveAndPublish, publishButtonLoading } = useArticleEditActions()

const { createCollabRoom } = useCollabHelpers()
const collabRoom = createCollabRoom(article.value.id, 'article', 'cms')
const { collabRoomInfo, kickUserFromRoom } = useCollabRoom(collabRoom)

const onConfirm = async () => {
  collabRoomInfo.value.users.forEach((user) => {
    if (collabRoomInfo.value.moderator !== user) {
      kickUserFromRoom(user)
    }
  })
  await saveAndPublish(article.value)
  mainBarDialogsStore.closeDialog()
}

const onClose = () => {
  mainBarDialogsStore.closeDialog()
}
</script>

<template>
  <VDialog
    :model-value="true"
    :width="700"
  >
    <VCard>
      <ADialogToolbar @on-cancel="onClose">
        {{ t('cms.articleKind.action.collabActive') }}
      </ADialogToolbar>
      <VCardText>
        <p>
          {{ t('cms.articleKind.action.desc.kickUsersBeforePublish') }}
        </p>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnTertiary @click.stop="onClose">
          {{ t('common.button.cancel') }}
        </ABtnTertiary>
        <ABtnPrimary
          :loading="publishButtonLoading"
          @click.stop="onConfirm"
        >
          {{ t('common.button.confirm') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
