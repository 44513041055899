import { EmbedEntity } from '@/components/anzutap/types/Embed'
import type CustomForm from '@/components/cms/CustomForm.vue'
import { useArticleMultiTitleFactory } from '@/model/cms/factory/ArticleMultiTitleFactory'
import { ArticleStatus, type ArticleStatusType } from '@/model/cms/valueObject/ArticleStatus'
import { ROUTE } from '@/router/routes'
import {
  apiUpdatePromoLinkItems,
  changeArticleStatus,
  createArticle,
  createArticleDraftFromPublished,
  deleteArticle,
  fetchArticle,
  fetchArticleListByDocId,
  fetchArticleListVersionData,
  updateArticle,
} from '@/services/api/cms/articleApi'
import { upsertArticleMultiTitles } from '@/services/api/cms/articleMultiTitleApi'
import { removeTextsOverridesBoxItems } from '@/services/api/cms/boxItemApi'
import { fetchEmbedList } from '@/services/api/cms/embed/embedApi'
import { useArticleOneStore } from '@/stores/cms/articleStore'
import { useArticleMultiTitleOneStore } from '@/stores/cms/articleMultiTitleStore'
import { useEmbedStore } from '@/stores/cms/embedStore'
import type { Article, ArticleStagesChangeCollabUpdate } from '@/types/cms/Article/Article'
import type { ArticleCreateDto } from '@/types/cms/Article/ArticleCreateDto'
import type { PromoLinkItem } from '@/types/cms/PromoLink'
import type { CollabRoom } from '@anzusystems/common-admin'
import {
  type DocId,
  type FilterBag,
  type IntegerId,
  isAnzuApiValidationError,
  isNull,
  isNumber,
  isString,
  isUndefined,
  objectSetValueByPath,
  type Pagination,
  type RecordWasType,
  useAlerts,
  useCollabAnyDataChange,
  useCollabHelpers,
} from '@anzusystems/common-admin'
import { useMainBarDialogsStore } from '@/views/cms/article/components/mainBarButtons/mainBarDialogsStore'
import { useArticleWidget } from '@/views/cms/article/components/widgets/articleWidget'
import { useArticleRelatedEntitiesActions } from '@/views/cms/article/composables/articleRelatedEntitiesActions'
import { useArticleMultiTitleCreateAndEditActions } from '@/views/cms/articleMultiTitle/composables/articleMultiTitleActions'
import { useCachedAuthors } from '@/views/cms/author/composables/cachedAuthors'
import { useCachedDesks } from '@/views/cms/desk/composables/cachedDesks'
import { useCachedPromoLinks } from '@/views/cms/promoLink/composables/cachedPromoLinks'
import { useCachedRubrics } from '@/views/cms/rubric/composables/cachedRubrics'
import { getSiteFromCachedOrLoad, useCachedSites } from '@/views/cms/site/composables/cachedSites'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import type { Fn } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, type Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStages } from '@/views/cms/stage/composables/stagesAll'
import { getArticleStats } from '@/services/api/cms/articleStatApi'
import { useArticleStatDtoFactory } from '@/model/cms/factory/ArticleStat/ArticleStatDtoFactory'
import type { ArticleStatDto } from '@/types/cms/ArticleStat/ArticleStatRequestDto'
import type { ArticleStat } from '@/types/cms/ArticleStat/ArticleStat'
import type { ThirdPartyTrackerDto } from '@/types/cms/ThirdPartyTracker'
import type { ArticlePurchaseDto } from '@/types/cms/ArticlePurchase/ArticlePurchaseRequestDto'
import { useArticlePurchaseDtoFactory } from '@/model/cms/factory/ArticlePurchase/ArticlePurchaseDtoFactory'
import { getArticlePurchases } from '@/services/api/cms/articlePurchaseApi'
import type { ArticlePurchase } from '@/types/cms/ArticlePurchase'

const { addToCachedUsers, fetchCachedUsers } = useCachedUsers()
const { addToCachedAuthors, fetchCachedAuthors } = useCachedAuthors()
const { addToCachedSites, fetchCachedSites } = useCachedSites()
const { addToCachedRubrics, fetchCachedRubrics } = useCachedRubrics()
const { addToCachedDesks, fetchCachedDesks } = useCachedDesks()
const { addToCachedPromoLinks, fetchCachedPromoLinks } = useCachedPromoLinks()
const { showValidationError, showRecordWas, showErrorsDefault, showError, showErrorT } = useAlerts()
const { createArticleStatDto } = useArticleStatDtoFactory()
const { createArticlePurchaseDto } = useArticlePurchaseDtoFactory()

const datatableHiddenColumns = ref<Array<string>>([
  'id',
  'docId',
  'version',
  'discriminator',
  'site',
  'desk',
  'dates.publicPublishedAt',
  'dates.expireAt',
  'modifiedAt',
  'stages',
])
const listLoading = ref(false)
const detailLoading = ref(true)
const saveButtonLoading = ref(false)
const autosaveLoading = ref(false)
const autosavePromise = ref<Promise<ArticleAutosaveReturnType> | null>(null)
const publishButtonLoading = ref(false)
const createButtonLoading = ref(false)
const updatePromoLinkItemsLoading = ref(false)
const customFormInstance = ref<InstanceType<typeof CustomForm> | null>(null)
const expanded = ref<string[]>([]) // todo fix when vuetify fixes its type, it should be IntegerId[]

export const COLLAB_FIELD_PREFIX_STAGES_CHANGE = 'articleStagesChange:'
export const COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE = 'articleLockingVersion'

export const useArticleListActions = () => {
  const articleOneStore = useArticleOneStore()
  const { listItems } = storeToRefs(articleOneStore)
  const { loadStages } = useStages()

  const fetchList = async (pagination: Pagination, filterBag: FilterBag) => {
    listLoading.value = true
    try {
      const articleStatDtoList: Array<ArticleStatDto> = []
      const articlePurchaseDtoList: Array<ArticlePurchaseDto> = []
      const res = await fetchArticleListVersionData(pagination, filterBag)
      listItems.value = res.items
      listItems.value.forEach((item) => {
        articleStatDtoList.push(createArticleStatDto(item.docId))
        articlePurchaseDtoList.push(createArticlePurchaseDto(item.docId))
        addToCachedSites(item.site)
        addToCachedRubrics(item.rubric)
        addToCachedDesks(item.desk)
        addToCachedAuthors(item.articleAuthors.map((articleAuthor) => articleAuthor.author))
        if (item.versionsData) {
          addToCachedSites(item.versionsData.site)
          addToCachedRubrics(item.versionsData.rubric)
          addToCachedDesks(item.versionsData.desk)
          addToCachedAuthors(item.articleAuthors.map((articleAuthor) => articleAuthor.author))
        }
      })
      const articleStatRes = await getArticleStats(articleStatDtoList)
      listItems.value.forEach((item) => {
        item.stats = articleStatRes.find((articleStatDto) => articleStatDto.articleDocId === item.docId) as ArticleStat
      })
      const articlePurchaseRes = await getArticlePurchases(articlePurchaseDtoList)
      listItems.value.forEach((item) => {
        item.purchases = articlePurchaseRes.filter(
          (articlePurchaseDto) => articlePurchaseDto.articleDocId === item.docId
        ) as ArticlePurchase[]
      })
      expanded.value = res.hasVersionData as unknown as string[] // todo fix when vuetify fixes its type
      fetchCachedSites()
      fetchCachedRubrics()
      fetchCachedDesks()
      fetchCachedAuthors()
      loadStages()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      listLoading.value = false
    }
  }

  return {
    expanded,
    datatableHiddenColumns,
    listLoading,
    fetchList,
    listItems,
  }
}

export const useArticleDetailActions = () => {
  const articleOneStore = useArticleOneStore()
  const embedStore = useEmbedStore()
  const mainBarDialogsStore = useMainBarDialogsStore()
  const { article, articleSite, bodyEditorReady } = storeToRefs(articleOneStore)
  const { lazyLoadUserPinnedWidgets } = useArticleWidget()
  const { fetchArticleMultiTitles } = useArticleMultiTitleCreateAndEditActions()
  const { fetchRelatedEntities } = useArticleRelatedEntitiesActions()

  onBeforeUnmount(() => {
    bodyEditorReady.value = false
    embedStore.reset()
    articleOneStore.reset()
  })

  const fetchData = async (id: IntegerId, fetchEmbeds = false) => {
    detailLoading.value = true
    embedStore.setCurrentEntity(EmbedEntity.Article, id)
    try {
      await lazyLoadUserPinnedWidgets()
      const articleRes = await fetchArticle(id)
      window.history.replaceState(window.history.state, '', `/article/${articleRes.docId}/${articleRes.version}`)
      mainBarDialogsStore.article = articleRes
      articleSite.value = await getSiteFromCachedOrLoad(articleRes.site)
      embedStore.setCurrentSiteGroup(articleRes.siteGroup)
      addToCachedUsers(articleRes.owners, articleRes.firstPublishedBy, articleRes.createdBy, articleRes.modifiedBy)
      addToCachedRubrics(articleRes.rubric)
      addToCachedPromoLinks(articleRes.promoLinkItems.map((promoLinkItem: PromoLinkItem) => promoLinkItem.promoLink))
      addToCachedPromoLinks(articleRes.disabledPromoLinks)
      articleRes.articleAuthors.forEach((item) => {
        addToCachedAuthors(item.author)
      })
      addToCachedDesks(articleRes.desk)
      articleOneStore.article = articleRes
      fetchCachedUsers()
      fetchCachedRubrics()
      fetchCachedAuthors()
      fetchCachedPromoLinks()
      fetchCachedDesks()
      if (fetchEmbeds) {
        const embedsRes = await fetchEmbedList(id, EmbedEntity.Article)
        embedStore.addEmbeds(embedsRes)
      }
      await Promise.allSettled([fetchArticleMultiTitles(id), fetchRelatedEntities(id)])
      bodyEditorReady.value = true
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      detailLoading.value = false
    }
  }

  return {
    bodyEditorReady,
    article,
    detailLoading,
    fetchData,
    resetStore: articleOneStore.reset,
  }
}

export const useArticleCreateActions = () => {
  const router = useRouter()

  const onCreate = async (
    articleData: ArticleCreateDto,
    callbackSuccess: ((data: Article) => void) | undefined = undefined
  ) => {
    try {
      createButtonLoading.value = true
      const articleRes = await createArticle(articleData)
      showRecordWas('created')
      if (!isUndefined(callbackSuccess)) callbackSuccess(articleRes)
      router.push({ name: ROUTE.CMS.ARTICLE.EDIT, params: { id: articleRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      createButtonLoading.value = false
    }
  }

  return {
    createButtonLoading,
    onCreate,
  }
}

export const useArticleEditActions = () => {
  const router = useRouter()
  const articleOneStore = useArticleOneStore()
  const articleMultiTitleOneStore = useArticleMultiTitleOneStore()
  const embedStore = useEmbedStore()
  const mainBarDialogsStore = useMainBarDialogsStore()
  const { lazyLoadUserPinnedWidgets } = useArticleWidget()
  const { fetchArticleMultiTitles } = useArticleMultiTitleCreateAndEditActions()
  const { createUpsertMultiTitleDto } = useArticleMultiTitleFactory()
  const { fetchRelatedEntities } = useArticleRelatedEntitiesActions()

  const { article, articleSite, bodyEditorReady } = storeToRefs(articleOneStore)

  const anySaveLoading = computed(() => {
    return saveButtonLoading.value || autosaveLoading.value
  })

  const updateArticleMetadata = (newArticle: Article) => {
    article.value.createdAt = newArticle.createdAt
    article.value.modifiedAt = newArticle.modifiedAt
    article.value.createdBy = newArticle.createdBy
    article.value.modifiedBy = newArticle.modifiedBy
    article.value.firstPublishedBy = newArticle.firstPublishedBy
    addToCachedUsers(newArticle.firstPublishedBy, newArticle.createdBy, newArticle.modifiedBy)
    fetchCachedUsers()
  }

  const fetchData = async (id: IntegerId, fetchEmbeds: boolean, collabRoomName: CollabRoom) => {
    detailLoading.value = true
    embedStore.setCurrentEntity(EmbedEntity.Article, id)
    embedStore.setCurrentRoomName(collabRoomName)
    try {
      await lazyLoadUserPinnedWidgets()
      const articleRes = await fetchArticle(id)
      window.history.replaceState(window.history.state, '', `/article/${articleRes.docId}/${articleRes.version}/edit`)
      mainBarDialogsStore.article = articleRes
      embedStore.setCurrentSiteGroup(articleRes.siteGroup)
      if (articleRes.status !== ArticleStatus.Draft) {
        detailLoading.value = false
        router.push({ name: ROUTE.CMS.ARTICLE.DETAIL, params: { id: articleRes.id } })
        return
      }
      articleSite.value = await getSiteFromCachedOrLoad(articleRes.site)
      addToCachedUsers(articleRes.firstPublishedBy, articleRes.createdBy, articleRes.modifiedBy)
      addToCachedPromoLinks(articleRes.promoLinkItems.map((promoLinkItem: PromoLinkItem) => promoLinkItem.promoLink))
      addToCachedPromoLinks(articleRes.disabledPromoLinks)
      addToCachedDesks(articleRes.desk)
      fetchCachedPromoLinks()
      fetchCachedUsers()
      fetchCachedDesks()
      if (fetchEmbeds) {
        const embedsRes = await fetchEmbedList(id, EmbedEntity.Article)
        embedStore.addEmbeds(embedsRes)
      }
      const articleStatRes = await getArticleStats([createArticleStatDto(articleRes.docId)])
      if (articleStatRes[0]) {
        articleRes.stats = articleStatRes[0]
      }
      articleOneStore.article = articleRes
      await Promise.allSettled([fetchArticleMultiTitles(id), fetchRelatedEntities(id)])
      bodyEditorReady.value = true
    } catch (error) {
      showErrorsDefault(error)
      router.push({ name: ROUTE.CMS.ARTICLE.LIST })
    } finally {
      detailLoading.value = false
    }
  }

  const onUpdate = async () => {
    if (anySaveLoading.value) return false
    saveButtonLoading.value = true
    if (isUndefined(articleOneStore.vScope)) {
      saveButtonLoading.value = false
      return false
    }
    const { createCollabRoom } = useCollabHelpers()
    const { changeCollabAnyData } = useCollabAnyDataChange(
      createCollabRoom(articleOneStore.article.id, 'article', 'cms')
    )
    try {
      const bodyUpdateWasSuccess = articleOneStore.updateBodyTextFromEditor()
      if (!bodyUpdateWasSuccess) {
        showError('Error: Unable to update article body text from editor.')
        saveButtonLoading.value = false
        return false
      }
      const validCustomData = await customFormInstance.value?.validate()
      articleOneStore.vScope.$touch()
      if (articleOneStore.vScope.$invalid || !validCustomData) {
        showValidationError()
        saveButtonLoading.value = false
        return false
      }
      if (articleMultiTitleOneStore.articleMultiTitles.length) {
        const upsertMultiTitleDto = createUpsertMultiTitleDto(
          articleOneStore.article.id,
          articleMultiTitleOneStore.articleMultiTitles
        )
        articleMultiTitleOneStore.articleMultiTitles = await upsertArticleMultiTitles(upsertMultiTitleDto)
      }
      if (articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts.length) {
        await removeTextsOverridesBoxItems({ boxItems: articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts })
        articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts = []
      }
      const newArticle = await updateArticle(articleOneStore.article.id, articleOneStore.article)
      articleOneStore.article.lockingVersion = newArticle.lockingVersion
      changeCollabAnyData(COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE, newArticle.lockingVersion)
      showRecordWas('updated')
      // updatePromoLinkItems() todo
      updateArticleMetadata(newArticle)
      fetchRelatedEntities(articleOneStore.article.id)
      return true
    } catch (error) {
      showErrorsDefault(error)
      return false
    } finally {
      saveButtonLoading.value = false
    }
  }

  const autosave = async (): Promise<ArticleAutosaveReturnType> => {
    if (!autosavePromise.value) {
      autosavePromise.value = autosaveAction()

      try {
        return await autosavePromise.value
      } finally {
        autosavePromise.value = null
      }
    }

    return await autosavePromise.value
  }

  const autosaveAction = async (): Promise<ArticleAutosaveReturnType> => {
    if (anySaveLoading.value) return 'ok'
    autosaveLoading.value = true
    if (isUndefined(articleOneStore.vScope)) {
      autosaveLoading.value = false
      return 'otherError'
    }
    const { createCollabRoom } = useCollabHelpers()
    const { changeCollabAnyData } = useCollabAnyDataChange(
      createCollabRoom(articleOneStore.article.id, 'article', 'cms')
    )
    try {
      const bodyUpdateWasSuccess = articleOneStore.updateBodyTextFromEditor()
      if (!bodyUpdateWasSuccess) {
        autosaveLoading.value = false
        return 'otherError'
      }
      const validCustomData = await customFormInstance.value?.validate()
      articleOneStore.vScope.$touch()
      if (articleOneStore.vScope.$invalid || !validCustomData) {
        autosaveLoading.value = false
        return 'validationError'
      }
      if (articleMultiTitleOneStore.articleMultiTitles.length) {
        const upsertMultiTitleDto = createUpsertMultiTitleDto(
          articleOneStore.article.id,
          articleMultiTitleOneStore.articleMultiTitles
        )
        articleMultiTitleOneStore.articleMultiTitles = await upsertArticleMultiTitles(upsertMultiTitleDto)
      }
      if (articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts.length) {
        await removeTextsOverridesBoxItems({ boxItems: articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts })
        articleMultiTitleOneStore.selectedBoxItemsToOverrideTexts = []
      }
      const newArticle = await updateArticle(articleOneStore.article.id, articleOneStore.article)
      articleOneStore.article.lockingVersion = newArticle.lockingVersion
      changeCollabAnyData(COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE, newArticle.lockingVersion)
      updateArticleMetadata(newArticle)
      fetchRelatedEntities(articleOneStore.article.id)
      return 'ok'
    } catch (error) {
      if (isAnzuApiValidationError(error)) {
        const hasLockingVersionError = error.fields.some(
          (item) =>
            item.field === 'cms.articleKind.model.lockingVersion' && item.errors.includes('entity_changed_meantime')
        )
        if (hasLockingVersionError) {
          return 'lockingVersionError'
        }
        return 'validationError'
      }
      return 'otherError'
    } finally {
      autosaveLoading.value = false
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updatePromoLinkItems = async () => {
    try {
      updatePromoLinkItemsLoading.value = true
      const articleRes = await apiUpdatePromoLinkItems(articleOneStore.article.id)
      article.value.promoLinkItems = articleRes.promoLinkItems
      addToCachedPromoLinks(articleRes.promoLinkItems.map((promoLinkItem: PromoLinkItem) => promoLinkItem.promoLink))
      fetchCachedPromoLinks()
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      updatePromoLinkItemsLoading.value = false
    }
  }

  const getStatusChangeVariant = (oldStatus: ArticleStatusType, newStatus: ArticleStatusType): RecordWasType => {
    if (oldStatus === ArticleStatus.Published && newStatus === ArticleStatus.Draft) {
      return 'unpublished'
    }
    if (newStatus === ArticleStatus.Ready) {
      return 'published'
    }
    return 'updated'
  }

  const changeStatus = async (article: Article, newStatus: ArticleStatusType, view = ROUTE.CMS.ARTICLE.DETAIL) => {
    try {
      const articleRes = await changeArticleStatus(article.id, newStatus)
      showRecordWas(getStatusChangeVariant(article.status, newStatus))
      articleOneStore.article = articleRes
      router.push({ name: view, params: { id: articleRes.id } })
      return true
    } catch (error) {
      showErrorsDefault(error)
      return false
    } finally {
      //
    }
  }

  const saveAndPublish = async (article: Article) => {
    publishButtonLoading.value = true

    if (!isNull(autosavePromise.value)) {
      await autosavePromise.value
    }
    try {
      const saveSuccess = await onUpdate()
      if (!saveSuccess) {
        showErrorT('cms.articleKind.alert.unableToPublishUnsavedArticle')
        return
      }
      await changeStatus(article, ArticleStatus.Ready)
    } catch (err) {
      showErrorsDefault(err)
    } finally {
      publishButtonLoading.value = false
    }
  }

  const createDraftFromPublished = async (article: Article, view = ROUTE.CMS.ARTICLE.DETAIL) => {
    try {
      const articleRes = await createArticleDraftFromPublished(article.id)
      showRecordWas('created')
      router.push({ name: view, params: { id: articleRes.id } })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      //
    }
  }

  return {
    detailLoading,
    updatePromoLinkItemsLoading,
    saveButtonLoading,
    publishButtonLoading,
    article,
    customFormInstance,
    autosaveLoading,
    anySaveLoading,
    fetchData,
    onUpdate,
    autosave,
    changeStatus,
    saveAndPublish,
    createDraftFromPublished,
    resetStore: articleOneStore.reset,
  }
}

export const useArticleDeleteActions = () => {
  const router = useRouter()

  const onDelete = async (id: IntegerId) => {
    try {
      await deleteArticle(id)
      showRecordWas('deleted')
      router.push({ name: ROUTE.CMS.ARTICLE.LIST })
    } catch (error) {
      showErrorsDefault(error)
    } finally {
      // closeDeleteDialog()
    }
  }

  return {
    onDelete,
  }
}

export const useArticleFindActions = () => {
  const findArticleLoading = ref(false)
  const foundArticle: Ref<Article | null> = ref(null)
  const findByDocId = async (docId: DocId) => {
    findArticleLoading.value = true
    let tempFoundArticle: Article | null = null
    const articles = await fetchArticleListByDocId(docId)
    articles.forEach((article) => {
      if (null === tempFoundArticle) {
        tempFoundArticle = article
        return
      }
      if (article.status !== ArticleStatus.Draft) {
        tempFoundArticle = article
      }
    })
    foundArticle.value = tempFoundArticle
    findArticleLoading.value = false
  }

  return {
    foundArticle,
    findArticleLoading,
    findByDocId,
  }
}

export const useArticleCollabActions = () => {
  const unsubscribe = ref<undefined | Fn>()
  const articleOneStore = useArticleOneStore()
  const { article, stageWidgetDatatableReload, articleBookmarksReload } = storeToRefs(articleOneStore)
  const multiTitleOneStore = useArticleMultiTitleOneStore()
  const { articleMultiTitles } = storeToRefs(multiTitleOneStore)

  const startArticleFieldsListeners = (room: CollabRoom) => {
    const { addCollabAnyDataChangeListener, unsubscribeCollabAnyDataChangeListener } = useCollabAnyDataChange(room)
    unsubscribe.value = unsubscribeCollabAnyDataChangeListener.value

    addCollabAnyDataChangeListener((field, data) => {
      if (field === 'articleBookmarks' && isNumber(data.value)) {
        articleBookmarksReload.value = data.value
        return
      }
      if (field.startsWith('multiTitleHeadline')) {
        const variant = field.replace('multiTitleHeadline:', '')
        return void articleMultiTitles.value.forEach((multiTitle) => {
          if (multiTitle.variant === variant && isString(data.value)) multiTitle.headline = data.value
        })
      }
      if (field.startsWith('multiTitlePerex')) {
        const variant = field.replace('multiTitlePerex:', '')
        return void articleMultiTitles.value.forEach((multiTitle) => {
          if (multiTitle.variant === variant && isString(data.value)) multiTitle.perex = data.value
        })
      }
      if (field.startsWith(COLLAB_FIELD_PREFIX_STAGES_CHANGE)) {
        const collabData = data.value as ArticleStagesChangeCollabUpdate | null
        if (isNull(collabData)) return
        article.value.activeStages = collabData.data.activeStages
        article.value.completedStages = collabData.data.completedStages
        stageWidgetDatatableReload.value++
        return
      }
      if (field === COLLAB_FIELD_NAME_LOCKING_VERSION_CHANGE && isNumber(data.value)) {
        article.value.lockingVersion = data.value
      }
      if (field === 'thirdPartyTrackers') {
        article.value.thirdPartyTrackers = data.value as ThirdPartyTrackerDto[]
      }
      if (field === 'bodyEditor') return
      objectSetValueByPath(article.value, field, data.value)
    })
  }

  const stopArticleFieldsListeners = () => {
    unsubscribe.value?.()
  }

  return {
    startArticleFieldsListeners,
    stopArticleFieldsListeners,
  }
}

export const ArticleAutosaveReturn = {
  Ok: 'ok',
  ValidationError: 'validationError',
  LockingVersionError: 'lockingVersionError',
  OtherError: 'otherError',
} as const
export type ArticleAutosaveReturnType = (typeof ArticleAutosaveReturn)[keyof typeof ArticleAutosaveReturn]
