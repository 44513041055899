import { ref } from 'vue'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const CustomFormResourceName = {
  Article: 'articleKind',
  AuthorKind: 'authorKind',
  Rubric: 'rubric',
  Site: 'site',
  LinkedList: 'linkedList',
  Page: 'page',
  Poll: 'poll',
  Gallery: 'gallery',
  EmbedKindGallery: 'embedKindGallery',
  EmbedKindImage: 'embedKindImage',
  EmbedKindPoll: 'embedKindPoll',
  EmbedKindQuiz: 'embedKindPoll',
  EmbedKindRelated: 'embedKindRelated',
  EmbedKindCustom: 'embedKindCustom',
  EmbedKindWeather: 'embedKindWeather',
  EmbedKindMinute: 'embedKindMinute',
  EmbedKindCrossBox: 'embedKindCrossBox',
  EmbedKindDangerBox: 'embedKindDangerBox',
  EmbedKindTimeline: 'embedKindTimeline',
  EmbedKindFaq: 'embedKindFaq',
  EmbedKindExternal: 'embedKindExternal',
  EmbedKindReview: 'embedKindReview',
  EmbedKindVideo: 'embedKindVideo',
  EmbedKindAudio: 'embedKindAudio',
} as const
export type CustomFormResourceNameType = (typeof CustomFormResourceName)[keyof typeof CustomFormResourceName]
export const CustomFormResourceNameAll = Object.values(CustomFormResourceName)

export function useCustomFormResourceName() {
  const customFormResourceNameOptions = ref<ValueObjectOption<CustomFormResourceNameType>[]>(
    CustomFormResourceNameAll.map((name) => {
      return {
        value: name,
        title: name,
      }
    })
  )

  const getCustomFormResourceNameOption = (value: CustomFormResourceNameType) => {
    return customFormResourceNameOptions.value.find((item) => item.value === value)
  }

  return {
    customFormResourceNameOptions,
    getCustomFormResourceNameOption,
  }
}
