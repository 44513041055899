import type { DatetimeUTCNullable, IntegerId } from '@anzusystems/common-admin'
import {
  apiAnyRequest
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { ArticlePurchase } from '@/types/cms/ArticlePurchase'
import type {
  ArticlePurchaseDto,
  ArticlePurchaseRequestDto
} from '@/types/cms/ArticlePurchase/ArticlePurchaseRequestDto'

const END_POINT = '/adm/v1/article-purchase'
export const ENTITY = 'articlePurchase'

export const getArticlePurchases = (data: Array<ArticlePurchaseDto>) =>
  apiAnyRequest<ArticlePurchaseRequestDto, Array<ArticlePurchase>>(
    cmsClient,
    'POST',
    END_POINT + '/list',
    undefined,
    {
      purchases: data,
    },
    SYSTEM_CMS,
    ENTITY
  )

export const fetchSumByTime = (
  id: IntegerId,
  from: DatetimeUTCNullable,
  to: DatetimeUTCNullable
) => {
  return apiAnyRequest(
    cmsClient,
    'POST',
    END_POINT + '/sum-by-time/:id',
    { id },
    {
      from: from,
      to: to,
    },
    SYSTEM_CMS,
    ENTITY
  )
}
