<script lang="ts" setup>
import {
  AActionCloseButton,
  AActionEditButton,
  AActionDeleteButton,
  ACard,
  stringToInt, defineBreadcrumbs,
} from '@anzusystems/common-admin'
import { ROUTE } from '@/router/routes'
import { onBeforeUnmount, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import NotificationDetail from '@/views/cms/notification/components/NotificationDetail.vue'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import {
  useNotificationDeleteActions,
  useNotificationDetailActions,
} from '@/views/cms/notification/composables/notificationActions'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const route = useRoute()
const id = stringToInt(route.params.id)

const { onDelete } = useNotificationDeleteActions()
const { detailLoading, fetchData, resetStore, notification } = useNotificationDetailActions()

const { t } = useI18n()

const breadcrumbs = defineBreadcrumbs(
    computed(() => [
      { title: t('breadcrumb.cms.notification.list'), routeName: ROUTE.CMS.NOTIFICATION.LIST },
      {
        title: notification.value.texts.headline || t('breadcrumb.cms.notification.edit'),
        routeName: ROUTE.CMS.NOTIFICATION.EDIT,
        id,
      },
    ])
)

const getDetail = () => {
  fetchData(id)
}

onMounted(() => {
  getDetail()
})

onBeforeUnmount(() => {
  resetStore()
})
</script>

<template>
  <ActionbarWrapper :breadcrumbs="breadcrumbs">
    <template #buttons>
      <AActionEditButton
        v-if="!detailLoading && notification.sentAt === null"
        :record-id="id"
        :route-name="ROUTE.CMS.NOTIFICATION.EDIT"
      />
      <AActionDeleteButton
        v-if="!detailLoading && notification.sentAt === null"
        @delete-record="onDelete(id)"
      />
      <AActionCloseButton :route-name="ROUTE.CMS.NOTIFICATION.LIST" />
    </template>
  </ActionbarWrapper>

  <ACard :loading="detailLoading">
    <VCardText>
      <NotificationDetail />
    </VCardText>
  </ACard>
</template>
