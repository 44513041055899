import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { ACL } from '@/composables/auth/auth'

export const extSystemCallbackRoutes: RouteRecordRaw[] = [
  {
    path: '/ext-system-callback',
    name: ROUTE.CMS.EXT_SYSTEM_CALLBACK.LIST,
    component: () => import('@/views/cms/extSystemCallback/ExtSystemCallbackListView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-callback/create',
    name: ROUTE.CMS.EXT_SYSTEM_CALLBACK.CREATE,
    component: () => import('@/views/cms/extSystemCallback/ExtSystemCallbackCreateView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_CALLBACK_CREATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-callback/:id(\\d+)/edit',
    name: ROUTE.CMS.EXT_SYSTEM_CALLBACK.EDIT,
    component: () => import('@/views/cms/extSystemCallback/ExtSystemCallbackEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_CALLBACK_READ, ACL.CMS_EXT_SYSTEM_CALLBACK_UPDATE],
      layout: 'AppLayoutDrawer',
    },
  },
  {
    path: '/ext-system-callback/:id(\\d+)',
    name: ROUTE.CMS.EXT_SYSTEM_CALLBACK.DETAIL,
    component: () => import('@/views/cms/extSystemCallback/ExtSystemCallbackDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [ACL.CMS_EXT_SYSTEM_CALLBACK_READ],
      layout: 'AppLayoutDrawer',
    },
  },
]
