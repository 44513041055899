import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const NotificationFamily = {
  Article: 'article',
  Link: 'link',
} as const
export const NotificationFamilyDefault = NotificationFamily.Article
export type NotificationFamilyType = (typeof NotificationFamily)[keyof typeof NotificationFamily]

export function useNotificationFamily() {
  const { t } = useI18n()

  const notificationFamilyOptions = ref<ValueObjectOption<NotificationFamilyType>[]>([
    {
      value: NotificationFamily.Article,
      title: t('cms.notification.family.article'),
    },
    {
      value: NotificationFamily.Link,
      title: t('cms.notification.family.link'),
    },
  ])

  const getNotificationFamilyOption = (value: NotificationFamilyType) => {
    return notificationFamilyOptions.value.find((item) => item.value === value)
  }

  return {
    notificationFamilyOptions: notificationFamilyOptions,
    getNotificationFamilyOption: getNotificationFamilyOption,
  }
}
