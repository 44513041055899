import type { FilterBag, IntegerId, Pagination } from '@anzusystems/common-admin'
import {
  apiCreateOne,
  apiDeleteOne,
  apiFetchByIds,
  apiFetchList,
  apiFetchOne,
  apiUpdateOne,
} from '@anzusystems/common-admin'
import { cmsClient } from '@/services/api/clients/cmsClient'
import { SYSTEM_CMS } from '@/model/systems'
import type { ArticlePublicPreview } from '@/types/cms/ArticlePublicPreview'

const END_POINT = '/adm/v1/article-public-preview'
export const ENTITY = 'articlePublicPreview'

export const fetchArticlePublicPreviewList = (pagination: Pagination, filterBag: FilterBag) =>
  apiFetchList<ArticlePublicPreview[]>(cmsClient, END_POINT, {}, pagination, filterBag, SYSTEM_CMS, ENTITY)

export const fetchArticlePublicPreviewListByIds = (ids: IntegerId[]) =>
  apiFetchByIds<ArticlePublicPreview[]>(cmsClient, ids, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const fetchArticlePublicPreview = (id: IntegerId) =>
  apiFetchOne<ArticlePublicPreview>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const createArticlePublicPreview = (data: ArticlePublicPreview) =>
  apiCreateOne<ArticlePublicPreview>(cmsClient, data, END_POINT, {}, SYSTEM_CMS, ENTITY)

export const updateArticlePublicPreview = (id: IntegerId, data: ArticlePublicPreview) =>
  apiUpdateOne<ArticlePublicPreview>(cmsClient, data, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)

export const deleteArticlePublicPreview = (id: IntegerId) =>
  apiDeleteOne<ArticlePublicPreview>(cmsClient, END_POINT + '/:id', { id }, SYSTEM_CMS, ENTITY)
