import { useContentItemKindAdvertFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindAdvertFactory'
import { useContentItemKindAggregationFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindAggregationFactory'
import { useContentItemKindArchiveFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindArchiveFactory'
import { useContentItemKindArticleListFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindArticleListFactory'
import { useContentItemKindBoxFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindBoxFactory'
import { useContentItemKindBoxHistoryFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindBoxHistoryFactory'
import { useContentItemKindBreakingNewsFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindBreakingNewsFactory'
import { useContentItemKindCountdownFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindCountdownFactory'
import { useContentItemKindCrossBoxFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindCrossBoxFactory'
import { useContentItemKindFaqFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFaqFactory'
import { useContentItemKindImportantNewsFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindImportantNewsFactory'
import { useContentItemKindLinkedListFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindLinkedListFactory'
import { useContentItemKindNewsroomSectionFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindNewsroomSection'
import { useContentItemKindPageHeaderFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindPageHeaderFactory'
import { useContentItemKindRempBannerFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindRempBannerFactory'
import { useContentItemKindSearchFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindSearchFactory'
import { useContentItemKindTeleportFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindTeleportFactory'
import { useContentItemKindThematicBoxFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindThematicBoxFactory'
import { useContentItemKindTimelineFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindTimelineFactory'
import { useContentItemKindTrendingArticleListFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindTrendingArticleListFactory'
import { useContentItemKindUserWeatherFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindUserWeatherFactory'
import { useContentItemKindWeatherFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindWeatherFactory'
import { useContentItemKindWysiwygFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindWysiwygFactory'
import { SYSTEM_CMS } from '@/model/systems'
import type {
  ContentItemDetailDiscriminatorType,
  ContentItemDetailDiscriminatorTypeMap,
  ContentItemDetailKind,
  ContentItemDiscriminatorType,
  ContentItemDiscriminatorTypeMap,
  ContentItemKind,
} from '@/types/cms/ContentItemKind/ContentItemKind'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import { useContentItemKindDangerBoxFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindDangerBoxFactory'

export type AbstractContentItemKindType = Omit<ContentItemKind, 'discriminator' | '_resourceName'>
export type AbstractContentItemDetailKindType = Omit<ContentItemDetailKind, 'discriminator' | '_resourceName'>
type FactoryReturnType<TDiscriminator extends ContentItemDiscriminatorType> =
  ContentItemDiscriminatorTypeMap[TDiscriminator]
type FactoryDetailReturnType<TDiscriminator extends ContentItemDetailDiscriminatorType> =
  ContentItemDetailDiscriminatorTypeMap[TDiscriminator]

export function useContentItemKindFactory() {
  const createAbstractContentItemDetailKind = (): AbstractContentItemDetailKindType => {
    return {
      id: 0,
      publicId: null,
      forcedSynchronicity: false,
      main: false,
      teleportToIdentifier: '',
      _system: SYSTEM_CMS,
    }
  }

  const createAbstractContentItemKind = (): AbstractContentItemKindType => {
    return {
      id: 0,
      forcedSynchronicity: false,
      teleportToIdentifier: '',
      position: 0,
      _system: SYSTEM_CMS,
    }
  }

  const createContentItemKind = <TItem extends ContentItemDiscriminatorType>(
    discriminator: TItem
  ): FactoryReturnType<TItem> => {
    switch (discriminator) {
      case ContentItemDiscriminator.Advert: {
        const { create } = useContentItemKindAdvertFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Aggregation: {
        const { create } = useContentItemKindAggregationFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Archive: {
        const { create } = useContentItemKindArchiveFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Box: {
        const { create } = useContentItemKindBoxFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.BoxHistory: {
        const { create } = useContentItemKindBoxHistoryFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.BreakingNews: {
        const { create } = useContentItemKindBreakingNewsFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Countdown: {
        const { create } = useContentItemKindCountdownFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.CrossBox: {
        const { create } = useContentItemKindCrossBoxFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.DangerBox: {
        const { create } = useContentItemKindDangerBoxFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Faq: {
        const { create } = useContentItemKindFaqFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.ImportantNews: {
        const { create } = useContentItemKindImportantNewsFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.LinkedList: {
        const { create } = useContentItemKindLinkedListFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.NewsroomSection: {
        const { create } = useContentItemKindNewsroomSectionFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.TrendingArticleList: {
        const { create } = useContentItemKindTrendingArticleListFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.PageHeader: {
        const { create } = useContentItemKindPageHeaderFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.RempBanner: {
        const { create } = useContentItemKindRempBannerFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Search: {
        const { create } = useContentItemKindSearchFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Teleport: {
        const { create } = useContentItemKindTeleportFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.ThematicBox: {
        const { create } = useContentItemKindThematicBoxFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Timeline: {
        const { create } = useContentItemKindTimelineFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.UserWeather: {
        const { create } = useContentItemKindUserWeatherFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Weather: {
        const { create } = useContentItemKindWeatherFactory()
        return create() as FactoryReturnType<TItem>
      }
      case ContentItemDiscriminator.Wysiwyg: {
        const { create } = useContentItemKindWysiwygFactory()
        return create() as FactoryReturnType<TItem>
      }
      default: {
        const { create } = useContentItemKindArticleListFactory()
        return create() as FactoryReturnType<TItem>
      }
    }
  }

  const createContentItemDetailKind = <TItem extends ContentItemDetailDiscriminatorType>(
    discriminator: TItem
  ): FactoryDetailReturnType<TItem> => {
    switch (discriminator) {
      case ContentItemDiscriminator.BreakingNews: {
        const { createDetail } = useContentItemKindBreakingNewsFactory()
        return createDetail() as FactoryDetailReturnType<TItem>
      }
      default: {
        const { createDetail } = useContentItemKindImportantNewsFactory()
        return createDetail() as FactoryDetailReturnType<TItem>
      }
    }
  }

  return {
    createAbstractContentItemKind,
    createAbstractContentItemDetailKind,
    createContentItemKind,
    createContentItemDetailKind,
  }
}
