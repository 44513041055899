import type { DocId } from '@anzusystems/common-admin'
import type { ArticlePurchaseDto } from '@/types/cms/ArticlePurchase/ArticlePurchaseRequestDto'

export function useArticlePurchaseDtoFactory() {
  const createArticlePurchaseDto = (docId: DocId): ArticlePurchaseDto => {
    return {
      docId: docId,
    }
  }

  return {
    createArticlePurchaseDto,
  }
}
