import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const NotificationPriority = {
  Normal: 'normal',
  High: 'high',
} as const

export type NotificationPriorityType = (typeof NotificationPriority)[keyof typeof NotificationPriority]
export const NotificationPriorityDefault = NotificationPriority.Normal

export function useNotificationPriority() {
  const { t } = useI18n()

  const notificationPriorityOptions = ref<ValueObjectOption<NotificationPriorityType>[]>([
    {
      value: NotificationPriority.Normal,
      title: t('cms.notification.priority.normal'),
      color: 'default',
    },
    {
      value: NotificationPriority.High,
      title: t('cms.notification.priority.high'),
      color: 'success',
    },
  ])

  const getNotificationPriorityOption = (value: NotificationPriorityType) => {
    return notificationPriorityOptions.value.find((item) => item.value === value)
  }

  return {
    notificationPriorityOptions,
    getNotificationPriorityOption,
  }
}
