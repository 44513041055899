<script setup lang="ts">
import { useArticleOneStore } from '@/stores/cms/articleStore'
import type { CollabRoom } from '@anzusystems/common-admin'
import { ARow, type CollabStatusType } from '@anzusystems/common-admin'
import NewsletterRemoteAutocomplete from '@/views/cms/newsletter/components/NewsletterRemoteAutocomplete.vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()

const articleOneStore = useArticleOneStore()
const { article } = storeToRefs(articleOneStore)
</script>

<template>
  <div>
    <ARow>
      <VSwitch
        v-model="article.attributesStandard.inTrending"
        :disabled="readonly"
        :label="t('cms.articleKind.model.attributesStandard.inTrending')"
      />
      <VSwitch
        v-model="article.flagsStandard.enableForum"
        :disabled="readonly"
        :label="t('cms.articleKind.model.flagsStandard.enableForum')"
      />
    </ARow>
    <NewsletterRemoteAutocomplete
      v-model="article.newsletter"
      :label="t('cms.articleKind.model.newsletter')"
    />
  </div>
</template>
