import { useArticleFactory } from '@/model/cms/factory/Article/ArticleFactory'
import type { ArticleWithVersionData } from '@/services/api/cms/articleApiHelpers'
import type { Article } from '@/types/cms/Article/Article'
import type { SiteMinimal } from '@/types/cms/Site'
import type { ArticleWidgetVariantType } from '@/views/cms/article/components/widgets/articleWidget'
import type { IntegerId } from '@anzusystems/common-admin'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, shallowRef } from 'vue'

export const useArticleOneStore = defineStore('cmsArticleOneStore', () => {
  const { createArticle } = useArticleFactory()

  const article = ref<Article>(createArticle())
  const vScope = ref<undefined | any>(undefined)
  const bodyEditor = shallowRef<any | undefined>(undefined) // todo check why type is causing the issue with IJ tools
  const bodyEditorReady = ref(false)
  const listItems = ref<Array<ArticleWithVersionData>>([])
  const articleSite = ref<SiteMinimal | undefined>(undefined)
  const pinnedWidgets = ref<ArticleWidgetVariantType[]>([])
  const pinnedWidgetsLoaded = ref<boolean | IntegerId>(false)
  const stageWidgetDatatableReload = ref(0)
  const articleBookmarksReload = ref(0)
  const anotherStatusArticle = ref<Article | undefined | false>(undefined)

  /**
   * @return If content was undefined, returns false, otherwise on success returns true.
   */
  function updateBodyTextFromEditor() {
    const bodyText = bodyEditor.value?.getJSON()
    if (!bodyText) {
      return false
    }
    article.value.texts.body = bodyText
    return true
  }

  function addActiveStage(stageId: IntegerId) {
    if (!article.value.activeStages.includes(stageId)) {
      article.value.activeStages.push(stageId)
    }

    const index = article.value.completedStages.indexOf(stageId)
    if (index >= 0) {
      article.value.completedStages.splice(index, 1)
    }
  }

  function addCompletedStage(stageId: IntegerId) {
    if (!article.value.completedStages.includes(stageId)) {
      article.value.completedStages.push(stageId)
    }

    const index = article.value.activeStages.indexOf(stageId)
    if (index >= 0) {
      article.value.activeStages.splice(index, 1)
    }
  }

  function reset() {
    article.value = createArticle()
    articleSite.value = undefined
    bodyEditor.value?.destroy()
    bodyEditor.value = undefined
    bodyEditorReady.value = false
    stageWidgetDatatableReload.value = 0
    articleBookmarksReload.value = 0
    vScope.value = undefined
    anotherStatusArticle.value = undefined
  }

  return {
    article,
    vScope,
    bodyEditor,
    listItems,
    articleSite,
    pinnedWidgets,
    pinnedWidgetsLoaded,
    bodyEditorReady,
    stageWidgetDatatableReload,
    articleBookmarksReload,
    anotherStatusArticle,
    updateBodyTextFromEditor,
    reset,
    addActiveStage,
    addCompletedStage,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticleOneStore, import.meta.hot))
}
