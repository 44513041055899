<script lang="ts" setup>
import {
  ACopyText,
  AFormTextarea,
  AFormTextField,
  AFormValueObjectOptionsSelect, AImageWidget,
  ARow,
  ASystemEntityScope, isNull,
} from '@anzusystems/common-admin'
import { SYSTEM_CMS } from '@/model/systems'
import { useI18n } from 'vue-i18n'
import { ENTITY } from '@/services/api/cms/notificationApi'
import { useNotificationCreateEditActions } from '@/views/cms/notification/composables/notificationActions'
import { NotificationFamily, useNotificationFamily } from '@/model/cms/valueObject/NotificationFamily.ts'
import { useNotificationValidation } from '@/views/cms/notification/composables/notificationValidation'
import { useNotificationPriority } from '@/model/cms/valueObject/NotificationPriority.ts'
import {
  getCmsSiteGroupDamSelectLicences,
  getCmsSiteGroupDamUploadLicence
} from '@/composables/cms/imageWidgetConfigProvider.ts'
import SiteGroupSiteSelect from '@/components/cms/SiteGroupSiteSelect.vue'
import { useNotificationDestination } from '@/model/cms/valueObject/NotificationDestination.ts'
import ArticleByDocIdChip from '@/views/cms/article/components/ArticleByDocIdChip.vue'
import ArticleSelectTable from '@/views/cms/article/components/ArticleSelectTable.vue'
import type { Article } from '@/types/cms/Article/Article.ts'

withDefaults(
  defineProps<{
    isEdit?: boolean
  }>(),
  {
    isEdit: false,
  }
)
const { detailLoading, notification } = useNotificationCreateEditActions()

const onBrowseConfirm = (data: Array<Article>) => {
  if (data.length > 0) {
    const article = data[0]
    notification.value.articleDocId = article.docId
    notification.value.texts.overline = article.texts.overline
    notification.value.texts.headline = article.texts.headline
    notification.value.texts.summary = article.texts.perex
  }
}

const { v$ } = useNotificationValidation(notification)
const { t } = useI18n()

const { notificationFamilyOptions } = useNotificationFamily()
const { notificationPriorityOptions } = useNotificationPriority()
const { notificationDestinationOptions } = useNotificationDestination()
</script>

<template>
  <ASystemEntityScope
    :system="SYSTEM_CMS"
    :subject="ENTITY"
  >
    <VRow>
      <VCol cols="8">
        <ARow :title="t('cms.notification.model.id')">
          <ACopyText :value="notification.id" />
        </ARow>
        <ARow>
          <SiteGroupSiteSelect
            v-model:site-group="notification.siteGroup"
            v-model:site="notification.site"
            :readonly="false"
            :label-site-group="t('cms.notification.model.siteGroup')"
            :label-site="t('cms.notification.model.site')"
            :v-site-group="v$.notification.siteGroup"
            :v-site="v$.notification.site"
          />
        </ARow>
        <ARow :title="t('cms.notification.model.notificationFamily')">
          <AFormValueObjectOptionsSelect
            v-model="notification.family"
            :items="notificationFamilyOptions"
          />
        </ARow>
        <ARow v-if="notification.family === NotificationFamily.Link">
          <AFormTextField
            v-model="notification.url"
            :label="t('cms.notification.model.url')"
            :v="v$.notification.url"
          />
        </ARow>
        <ARow v-if="notification.family === NotificationFamily.Article">
          <ArticleByDocIdChip
            v-if="notification.articleDocId"
            :key="notification.articleDocId"
            :doc-id="notification.articleDocId"
            class="d-inline-flex"
          />
          <ArticleSelectTable
            :max-count="1"
            :min-count="1"
            return-type="article"
            @on-confirm="onBrowseConfirm"
          >
            <template #activator="{ props: propsSelector }">
              <VBtn
                color="primary"
                variant="text"
                class="mr-2"
                size="small"
                v-bind="propsSelector"
                icon="mdi-search-web"
              />
            </template>
          </ArticleSelectTable>
        </ARow>
        <ARow>
          <AFormTextField
            v-model="notification.texts.overline"
            :v="v$.notification.texts.overline"
          />
        </ARow>
        <ARow>
          <AFormTextField
            v-model="notification.texts.headline"
            :v="v$.notification.texts.headline"
          />
        </ARow>
        <ARow>
          <AFormTextarea
            v-model="notification.texts.summary"
            :v="v$.notification.texts.summary"
          />
        </ARow>
        <ARow :title="t('cms.notification.model.notificationDestination')">
          <AFormValueObjectOptionsSelect
            v-model="notification.destination"
            :items="notificationDestinationOptions"
          />
        </ARow>
        <ARow :title="t('cms.notification.model.notificationPriority')">
          <AFormValueObjectOptionsSelect
            v-model="notification.priority"
            :items="notificationPriorityOptions"
          />
        </ARow>
      </VCol>
      <VCol cols="4">
        <ARow>
          <AImageWidget
            v-if="!detailLoading && !isNull(notification.siteGroup)"
            v-model="notification.image"
            :upload-licence="getCmsSiteGroupDamUploadLicence(notification.siteGroup, 'image', 'default')"
            :select-licences="getCmsSiteGroupDamSelectLicences(notification.siteGroup, 'image', 'default')"
            :label="t('cms.notification.model.image')"
            queue-key="image"
          />
        </ARow>
      </VCol>
    </VRow>
  </ASystemEntityScope>
</template>
