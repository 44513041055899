import type { ContentItemKindTypes } from '@/types/cms/ContentItemKind/ContentItemKind'
import type {
  AnzuUserAndTimeTrackingAware,
  DatetimeUTCNullable,
  IntegerId,
  IntegerIdNullable,
  SortableItemDataAware,
} from '@anzusystems/common-admin'
import type { JSONContent } from '@tiptap/core'
import type { UuidNullable } from '@/types/common'

export interface Page extends AnzuUserAndTimeTrackingAware {
  id: IntegerId
  texts: PageTexts
  flags: PageFlags
  enabled: boolean
  parent: IntegerIdNullable
  siteGroup: IntegerIdNullable
  site: IntegerIdNullable
  desk: IntegerIdNullable
  rubric: IntegerIdNullable
  newsletter: IntegerIdNullable
  layoutTemplate: IntegerIdNullable
  advertSettings: IntegerIdNullable
  designSettings: IntegerIdNullable
  notificationKey: string
  monitoring: PageMonitoring
  dates: PageDates
  owners: IntegerId[]
  supervisors: IntegerId[]
  linkedList: IntegerIdNullable
  contents: PageContent[]
  readonly mainContent: PageContent | null
  readonly siteName: string
  excludedAutoDistributions: IntegerId[]
  image: IntegerIdNullable
  searchPage: IntegerIdNullable
  seoImage: IntegerIdNullable
  seo: PageSeo
  analytics: PageAnalytics
  stats: PageStats
  _resourceName: 'page'
  _system: 'cms'
}

export interface PageTexts {
  title: string
  description: string
}

export interface PageSeo {
  title: string
  description: string
  slug: string
  keywords: string
  indexingType: PageIndexingTypeType
}

export interface PageAnalytics {
  rempPropertyToken: string
}

export interface PageMonitoring {
  enabled: boolean
  daysThreshold: number
}

export interface PageDates {
  lastContentAddedAt: DatetimeUTCNullable
}

export interface PageFlags {
  useLinkedListFromParent: boolean
  useDesignSettingsFromParent: boolean
  useAdvertSettingsFromParent: boolean
  notificationFollowEnabled: boolean
  enabledFollow: boolean
  useSearchPageFromParent: boolean
  useSeoImageFromParent: boolean
  useAnalyticsFromParent: boolean
  useDesignSettingsHeaderWithMainHeadline: boolean
  rssEnabled: boolean
  template: boolean
  shownAsRelated: boolean
  allowedIndirectKeywordModifications: boolean
}

export interface PageStats {
  displayEnabled: boolean
  semaphoreEnabled: boolean
  semaphoreOwlTimeSpentAggregationId: UuidNullable
  semaphoreIntervalA: number
  semaphoreIntervalB: number
}

export interface PageContent extends AnzuUserAndTimeTrackingAware, SortableItemDataAware {
  id: IntegerId
  position: number
  enabled: boolean
  texts: PageContentTexts
  attributes: PageContentAttributes
  page: IntegerIdNullable
  contentItem: ContentItemKindTypes
  targetPosition: IntegerId
  _resourceName: 'pageContent'
  _system: 'cms'
}

export interface PageContentTexts {
  title: string
}

export interface PageContentAttributes {
  main: boolean
}

export interface PageMinimal {
  id: IntegerId
  title: string
}

export interface PageDuplicateDto {
  page: IntegerIdNullable
  texts: PageTexts
  seo: {
    slug: string
  }
  contentItemsData: {
    rubric: IntegerIdNullable
    parent: IntegerIdNullable
    pageHeaderBody: JSONContent
    keywords: IntegerId[]
  }
}

export interface MultiPageUpdateKeywordsDto {
  pages: IntegerId[]
  addKeywords: IntegerId[]
  removeKeywords: IntegerId[]
}

export const CreateTab = {
  CreateAsNew: 'createAsNew',
  CreateFromLayoutTemplate: 'createFromLayoutTemplate',
  Help: 'help',
} as const
export type CreateTabType = (typeof CreateTab)[keyof typeof CreateTab]

export const PageIndexingType = {
  NoIndexNoFollow: 'noIndexNoFollow',
  IndexFollow: 'indexFollow',
  IndexFollowExtended: 'indexFollowExtended',
} as const
export type PageIndexingTypeType = (typeof PageIndexingType)[keyof typeof PageIndexingType]
export const PageIndexingTypeDefault = PageIndexingType.IndexFollowExtended
export interface GroupedPageContents {
  [targetPositionId: number]: PageContent[]
}
