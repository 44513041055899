import { acceptHMRUpdate, defineStore } from 'pinia'
import { useArticleStageFactory } from '@/model/cms/factory/ArticleStageFactory'
import type { ArticleStage } from '@/types/cms/ArticleStage'
import { ref } from 'vue'
import type { IntegerId } from '@anzusystems/common-admin'

import type { Article } from '@/types/cms/Article/Article'

export const useArticleStageOneStore = defineStore('cmsArticleStageOneStore', () => {
  const { createDefault } = useArticleStageFactory()

  const articleStage = ref<ArticleStage>(createDefault())

  function reset() {
    articleStage.value = createDefault()
  }

  return {
    articleStage,
    reset,
  }
})

export const useArticleStageListStore = defineStore('cmsArticleStageOneStore', () => {
  const listItems = ref<ArticleStage[]>([])
  const stageArticle = ref(new Map<IntegerId, Article>())
  const articleRelLoading = ref<boolean>(false)

  function addArticle(taskId: IntegerId, value: Article) {
    stageArticle.value.set(taskId, value)
  }

  function getArticleByArticleStage(id: IntegerId) {
    return stageArticle.value.get(id)
  }

  function reset() {
    listItems.value = []
    stageArticle.value.clear()
  }

  return {
    listItems,
    reset,
    getArticleByArticleStage,
    articleRelLoading,
    addArticle,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArticleStageOneStore, import.meta.hot))
  import.meta.hot.accept(acceptHMRUpdate(useArticleStageListStore, import.meta.hot))
}
