import type { AnzuUserAndTimeTrackingAware, IntegerId } from '@anzusystems/common-admin'

export interface DesignSettings extends AnzuUserAndTimeTrackingAware {
  id: IntegerId
  texts: DesignSettingsTexts
  attributes: DesignSettingsAttributes
  header: DesignSettingsHeader
  _resourceName: 'designSettings'
  _system: 'cms'
}

interface DesignSettingsTexts {
  title: string
  description: string
}

interface DesignSettingsAttributes {
  theme: DesignSettingsThemeType
  brickProjectName: string
  favicon: string
  articleUsable: boolean
}

interface DesignSettingsHeader {
  template: DesignSettingsHeaderTemplateType
  templateParams: Record<string, string>
}

export const DesignSettingsHeaderTemplate = {
  Sme: 'sme',
  Index: 'index',
  Korzar: 'korzar',
  SmeWithText: 'smeWithText',
  MyWithText: 'myWithText',
  SlovakSpectator: 'slovakSpectator',
  Novyny: 'novyny',
  DennikSport: 'dennikSport',
} as const
export const DesignSettingsHeaderTemplateDefault = DesignSettingsHeaderTemplate.Sme
export type DesignSettingsHeaderTemplateType =
  (typeof DesignSettingsHeaderTemplate)[keyof typeof DesignSettingsHeaderTemplate]

export const DesignSettingsTheme = {
  SmeRed: 'smeRed',
  SmeFuneral: 'smeFuneral',
  SmeGreen: 'smeGreen',
  SmePurple: 'smePurple',
  DennikSport: 'dennikSport',
} as const
export const DesignSettingsThemeDefault = DesignSettingsTheme.SmeRed
export type DesignSettingsThemeType = (typeof DesignSettingsTheme)[keyof typeof DesignSettingsTheme]

export interface DesignSettingsMinimal {
  id: IntegerId
  title: string
}
