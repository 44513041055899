<script setup lang="ts">
import { useArticleOneStore } from '@/stores/cms/articleStore'
import type { CollabRoom } from '@anzusystems/common-admin'
import { ARow, type CollabStatusType } from '@anzusystems/common-admin'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { t } = useI18n()

const articleOneStore = useArticleOneStore()
const { article } = storeToRefs(articleOneStore)
</script>

<template>
  <ARow>
    <VSwitch
      v-model="article.flagsStandard.enableAds"
      :disabled="readonly || !isModerator"
      :label="t('cms.articleKind.model.flagsStandard.enableAds')"
    />
    <VSwitch
      v-model="article.flagsStandard.enableAdsInContent"
      :disabled="readonly || !isModerator"
      :label="t('cms.articleKind.model.flagsStandard.enableAdsInContent')"
    />
    <VSwitch
      v-model="article.flagsStandard.enableVideoAdsInContent"
      :disabled="readonly || !isModerator"
      :label="t('cms.articleKind.model.flagsStandard.enableVideoAdsInContent')"
    />
    <VSwitch
      v-model="article.flagsStandard.prAuthor"
      :disabled="readonly || !isModerator"
      :label="t('cms.articleKind.model.flagsStandard.prAuthor')"
    />
  </ARow>
</template>
