<script setup lang="ts">
import { useArticleOneStore } from '@/stores/cms/articleStore'
import type { CollabRoom } from '@anzusystems/common-admin'
import {
  AFormDatetimePicker,
  ARow,
  type CollabFieldData,
  type CollabStatusType,
  dateTimePretty,
  isString,
  useAlerts,
  useCollabField,
  useCollabHelpers,
  useCommonAdminCollabOptions,
  usePagination,
} from '@anzusystems/common-admin'
import { useCachedUsers } from '@/views/cms/user/composables/cachedUsers'
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ArticlePublicPreview } from '@/types/cms/ArticlePublicPreview'
import { useArticlePublicPreviewFactory } from '@/model/cms/factory/ArticlePublicPreviewFactory'
import {
  createArticlePublicPreview,
  deleteArticlePublicPreview,
  fetchArticlePublicPreviewList,
  updateArticlePublicPreview,
} from '@/services/api/cms/articlePublicPreviewApi'
import { useArticlePublicPreviewFilter } from '@/model/cms/filter/ArticlePublicPreviewFilter'
import { useArticlePublicPreviewValidation } from '@/views/cms/article/composables/articleValidations'
import { useClipboard } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    collabRoom: CollabRoom
    collabStatus: CollabStatusType
    readonly: boolean
    isModerator: boolean
  }>(),
  {}
)

const { create } = useArticlePublicPreviewFactory()

const articleOneStore = useArticleOneStore()
const { article } = storeToRefs(articleOneStore)
const publicPreview = ref<ArticlePublicPreview>(create(article.value.id))
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const changeFieldData = ref((data: CollabFieldData) => {})
const edit = ref(false)

const { showErrorsDefault, showSuccessT, showValidationError } = useAlerts()

const status = ref<'loading' | 'loaded' | 'error'>('loading')

const init = async () => {
  try {
    const pagination = usePagination()
    const filter = useArticlePublicPreviewFilter()
    filter.article.model = article.value.id
    const publicPreviews = await fetchArticlePublicPreviewList(pagination, filter)
    if (publicPreviews.length) {
      publicPreview.value = publicPreviews[0]
    }
    status.value = 'loaded'
  } catch (error) {
    showErrorsDefault(error)
    status.value = 'error'
  }
}

const { createCollabFieldConfig } = useCollabHelpers()
const { cachedUsers } = useCachedUsers()
const { collabOptions } = useCommonAdminCollabOptions()

if (collabOptions.value.enabled) {
  const { changeCollabFieldData, addCollabFieldDataChangeListener } = useCollabField(
    props.collabRoom,
    'articlePublicPreview'
  )
  changeFieldData.value = changeCollabFieldData
  addCollabFieldDataChangeListener(() => {
    init()
  })
}

const { t } = useI18n()
const { v$, minDatePicker, maxDatePicker } = useArticlePublicPreviewValidation(publicPreview)

const createPreview = async () => {
  const publicPreviewCreate = create(article.value.id)
  try {
    status.value = 'loading'
    publicPreview.value = await createArticlePublicPreview(publicPreviewCreate)
    status.value = 'loaded'
    showSuccessT('cms.articlePublicPreview.meta.created')
    changeFieldData.value(publicPreview.value.id)
  } catch (error) {
    showErrorsDefault(error)
    status.value = 'error'
  }
}

const updatePreview = async () => {
  try {
    v$.value.$touch()
    if (v$.value.$invalid) {
      showValidationError()
      return
    }
    status.value = 'loading'
    publicPreview.value = await updateArticlePublicPreview(publicPreview.value.id, publicPreview.value)
    status.value = 'loaded'
    showSuccessT('cms.articlePublicPreview.meta.updated')
    changeFieldData.value(publicPreview.value.id)
    edit.value = false
  } catch (error) {
    showErrorsDefault(error)
    status.value = 'error'
  }
}

const deletePreview = async () => {
  try {
    status.value = 'loading'
    publicPreview.value = await deleteArticlePublicPreview(publicPreview.value.id)
    status.value = 'loaded'
    showSuccessT('cms.articlePublicPreview.meta.deleted')
    changeFieldData.value(null)
    publicPreview.value = create(article.value.id)
  } catch (error) {
    showErrorsDefault(error)
    status.value = 'error'
  }
}

const { copy, isSupported } = useClipboard()

const onCopy = () => {
  if (!isSupported) return
  if (isString(publicPreview.value.url) && publicPreview.value.url.length) {
    copy(publicPreview.value.url).then(() => {
      showSuccessT('common.alert.textWasCopied')
    })
  }
}

onMounted(() => {
  init()
})
</script>

<template>
  <ARow v-if="publicPreview.id">
    <VList>
      <VListItem
        v-for="preview in [publicPreview]"
        :key="preview.id"
        density="compact"
      >
        <template #title>
          <a
            v-if="status === 'loaded' && preview.url"
            :href="preview.url"
            class="word-break-all text-caption mr-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ preview.url }}
          </a>
          <AFormDatetimePicker
            v-if="edit && status === 'loaded' && !readonly"
            v-model="preview.expiresAt"
            class="mt-5"
            :min="minDatePicker"
            :max="maxDatePicker"
            width="70%"
            :label="t('cms.articlePublicPreview.model.expiresAt')"
            :collab="createCollabFieldConfig('articlePreviewExpires', collabRoom, cachedUsers)"
            :v="v$.articlePublicPreview.expiresAt"
          />
          <div v-if="!edit && status === 'loaded'">
            {{ t('cms.articlePublicPreview.model.expiresAt') }}: {{ dateTimePretty(publicPreview.expiresAt) }}
          </div>
          <div
            v-if="status === 'loading'"
            class="w-100 d-flex align-center justify-center"
          >
            <VProgressCircular
              :size="12"
              :width="2"
              indeterminate
            />
          </div>
        </template>
        <template #append>
          <div class="mt-5">
            <ABtnTertiary
              v-if="edit"
              size="small"
              :disabled="status === 'loading' || readonly"
              @click="updatePreview"
            >
              {{ t('common.button.save') }}
            </ABtnTertiary>
            <ABtnTertiary
              v-if="edit"
              size="small"
              :disabled="status === 'loading' || readonly"
              @click="edit = !edit"
            >
              {{ t('common.button.cancel') }}
            </ABtnTertiary>
            <span>
              <VBtn
                v-if="!edit"
                color="grey-lighten-1"
                icon="mdi-content-copy"
                variant="text"
                size="small"
                :disabled="status === 'loading'"
                @click="onCopy"
              />
              <VTooltip
                activator="parent"
                location="bottom"
              >
                {{ t('cms.articlePublicPreview.tooltip.copy') }}
              </VTooltip>
            </span>
            <span>
              <VBtn
                v-if="!edit"
                color="grey-lighten-1"
                icon="mdi-pencil"
                variant="text"
                size="small"
                :disabled="status === 'loading' || readonly"
                @click="edit = !edit"
              />
            </span>
            <span>
              <VBtn
                v-if="!edit"
                color="grey-lighten-1"
                icon="mdi-reload"
                variant="text"
                size="small"
                :disabled="status === 'loading' || readonly"
                @click="updatePreview"
              />
              <VTooltip
                activator="parent"
                location="bottom"
              >
                {{ t('cms.articlePublicPreview.tooltip.regenerate') }}
              </VTooltip>
            </span>
            <span>
              <VBtn
                v-if="!edit"
                color="grey-lighten-1"
                icon="mdi-trash-can"
                variant="text"
                size="small"
                :disabled="status === 'loading'"
                @click="deletePreview"
              />
              <VTooltip
                activator="parent"
                location="bottom"
              >
                {{ t('cms.articlePublicPreview.tooltip.delete') }}
              </VTooltip>
            </span>
          </div>
        </template>
      </VListItem>
      <span class="ml-4">{{ t('cms.articlePublicPreview.meta.usageInfo') }}</span>
    </VList>
  </ARow>
  <ARow v-else>
    <ABtnTertiary
      :loading="status === 'loading'"
      @click.stop="createPreview"
    >
      {{ t('cms.articlePublicPreview.button.create') }}
    </ABtnTertiary>
  </ARow>
</template>
