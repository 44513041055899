import { ROUTE } from '@/router/routes'
import type { RouteRecordRaw } from 'vue-router'
import { CollabRoomJoinStrategy, useCollabHelpers } from '@anzusystems/common-admin'

const { createCollabRoom } = useCollabHelpers()

export const cmsPlaygroundRoutes: RouteRecordRaw[] = [
  {
    path: '/_playground/collab/edit',
    name: ROUTE.CMS._PLAYGROUND.COLLAB_EDIT,
    component: () => import('@/views/cms/_playground/collab/CollabDemoEditView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
      collab: () => ({
        room: createCollabRoom(1, 'playground', 'cms'),
        joinStrategy: CollabRoomJoinStrategy.Moderated,
        occupiedOrKickedRedirectToRoute: ROUTE.CMS._PLAYGROUND.COLLAB_DETAIL,
        editors: ['testEditor'],
      }),
    },
  },
  {
    path: '/_playground/collab',
    name: ROUTE.CMS._PLAYGROUND.COLLAB_DETAIL,
    component: () => import('@/views/cms/_playground/collab/CollabDemoDetailView.vue'),
    meta: {
      requiresAuth: true,
      requiredPermissions: [],
      layout: 'AppLayoutDrawer',
    },
  },
]
