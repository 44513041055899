import {
  type ApiInfiniteResponseList,
  type DocId,
  type FilterBag,
  type IntegerId,
  isString,
  isUndefined,
} from '@anzusystems/common-admin'
import { ArticleStatus } from '@/model/cms/valueObject/ArticleStatus'
import { fetchArticleListByDocIds } from '@/services/api/cms/articleApi'
import type { Article } from '@/types/cms/Article/Article'

export interface ArticleListResponse extends ApiInfiniteResponseList<Article[]> {
  versionsData: Article[]
}

export interface ArticleWithVersionData extends Article {
  versionsData?: Article
}

export const filterUrlFilterBag = (filterBag: FilterBag) => {
  if (
    filterBag.url &&
    isString(filterBag.url.model) &&
    filterBag.url.model.length > 0 &&
    filterBag.url.model.includes('?')
  ) {
    const questionMarkIndex = filterBag.url.model.indexOf('?')
    if (questionMarkIndex !== -1) {
      filterBag.url.model = filterBag.url.model.slice(0, questionMarkIndex).trim()
    }
    const hashtagMarkIndex = filterBag.url.model.indexOf('#')
    if (hashtagMarkIndex !== -1) {
      filterBag.url.model = filterBag.url.model.slice(0, hashtagMarkIndex).trim()
    }
  }
}

export const mapVersionDataStandard = (items: Article[], versionItems: Article[]) => {
  const final: ArticleWithVersionData[] = []
  const hasVersionData: IntegerId[] = []
  const alreadyInList = new Set<IntegerId>()
  const versionItemsMap = new Map<IntegerId, Article>()
  versionItems.forEach((item) => {
    versionItemsMap.set(item.id, item)
  })
  items.forEach((item) => {
    if (!alreadyInList.has(item.id)) {
      if (item.articleVersions.length === 1) {
        const found = versionItemsMap.get(item.articleVersions[0])
        if (item.status === ArticleStatus.Published) {
          final.push({
            ...item,
            versionsData: found,
          })
          hasVersionData.push(item.id)
        } else if (!isUndefined(found)) {
          final.push({
            ...found,
            versionsData: item,
          })
          hasVersionData.push(found.id)
        }
        if (!isUndefined(found)) {
          alreadyInList.add(found.id)
        }
        alreadyInList.add(item.id)
      } else {
        final.push(item)
        alreadyInList.add(item.id)
      }
    }
  })

  return {
    items: final,
    hasVersionData,
  }
}

export const tryLoadLatestVersionOfArticle = async (docId: DocId) => {
  const res = await fetchArticleListByDocIds([docId])
  if (res.length === 0) {
    return null
  }

  return res.reduce((acc, current) => {
    return current.version > acc.version ? current : acc
  })
}
