<script lang="ts" setup>
import { useAuthorFilter } from '@/model/cms/filter/AuthorFilter'
import { useAuthorSelectActions } from '@/views/cms/author/composables/authorActions'
import {
  AFormRemoteAutocomplete,
  type IntegerId,
  type IntegerIdNullable,
  isNull,
  isNumber, isUndefined,
} from '@anzusystems/common-admin'
import { computed, watch } from 'vue'
import type { AuthorDiscriminatorType } from '@/model/cms/valueObject/AuthorDiscriminator'

const props = withDefaults(
  defineProps<{
    label?: string | undefined
    required?: boolean | undefined
    multiple?: boolean
    siteGroupId?: IntegerIdNullable
    siteGroupRequired?: boolean
    disabled?: boolean
    discriminator?: AuthorDiscriminatorType | undefined
  }>(),
  {
    label: undefined,
    required: undefined,
    multiple: false,
    siteGroupId: null,
    siteGroupRequired: false,
    disabled: false,
    discriminator: undefined,
  }
)

const modelValue = defineModel<IntegerIdNullable | IntegerId[]>({ required: true })

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const { fetchItems, fetchItemsByIds } = useAuthorSelectActions(isNull(props.siteGroupId))

const innerFilter = useAuthorFilter()

const disabledComputed = computed(() => {
  if (props.disabled) return true
  if (props.siteGroupRequired) {
    return !isNumber(props.siteGroupId)
  }
  return false
})

const siteGroupId = computed(() => (isNumber(props.siteGroupId) ? props.siteGroupId : undefined))

watch(
  siteGroupId,
  (newValue, oldValue) => {
    if (isNumber(newValue)) innerFilter.siteGroup.model = newValue
    if (newValue !== oldValue && oldValue) modelValue.value = props.multiple ? [] : null
  },
  { immediate: true }
)

watch(
  () => props.discriminator,
  (newValue) => {
    if (isUndefined(newValue)) {
      innerFilter.discriminator.model = null
      return
    }
    innerFilter.discriminator.model = newValue
  },
  { immediate: true }
)
</script>

<template>
  <AFormRemoteAutocomplete
    v-model="modelValue"
    :required="required"
    :label="label"
    :fetch-items="fetchItems"
    :fetch-items-by-ids="fetchItemsByIds"
    :inner-filter="innerFilter"
    :filter-sort-by="null"
    filter-by-field="text"
    :multiple="multiple"
    :disabled="disabledComputed"
  />
</template>
