import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { ValueObjectOption } from '@anzusystems/common-admin'

export const NotificationDestination = {
  App: 'app',
  Browser: 'browser',
} as const
export const NotificationDestinationDefault = NotificationDestination.App
export type NotificationDestinationType = (typeof NotificationDestination)[keyof typeof NotificationDestination]

export function useNotificationDestination() {
  const { t } = useI18n()

  const notificationDestinationOptions = ref<ValueObjectOption<NotificationDestinationType>[]>([
    {
      value: NotificationDestination.App,
      title: t('cms.notification.destination.app'),
    },
    {
      value: NotificationDestination.Browser,
      title: t('cms.notification.destination.browser'),
    },
  ])

  const getNotificationDestinationOption = (value: NotificationDestinationType) => {
    return notificationDestinationOptions.value.find((item) => item.value === value)
  }

  return {
    notificationDestinationOptions,
    getNotificationDestinationOption,
  }
}
