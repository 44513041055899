import { useDocumentFactory } from '@/components/anzutap/factory/DocumentFactory'
import { ArticleIcon } from '@/model/cms/valueObject/ArticleIcon'
import { ArticleLockTypeDefault } from '@/model/cms/valueObject/ArticleLockType'
import { ArticleStatusDefault } from '@/model/cms/valueObject/ArticleStatus'
import { SYSTEM_CMS } from '@/model/systems'
import type {
  Article,
  ArticleDates,
  ArticleFlagsStandard,
  ArticleRelatedEntities,
  ArticleSeo,
  ArticleTexts,
} from '@/types/cms/Article/Article'
import { dateTimeNow } from '@anzusystems/common-admin'
import { ENTITY_STANDARD } from '@/services/api/cms/articleApi'

export function useArticleFactory() {
  const createArticle = (): Article => {
    return {
      id: 0,
      docId: '',
      version: 1,
      lockingVersion: 1,
      status: ArticleStatusDefault,
      texts: createArticleTexts(),
      seo: createArticleSeo(),
      dates: createArticleDates(),
      leadImage: null,
      listingImage: null,
      socialImage: null,
      intention: null,
      site: 0,
      siteGroup: 0,
      rubric: 0,
      desk: 0,
      layoutTemplate: 0,
      articleAuthors: [],
      modifiedByUsers: [],
      owners: [],
      keywords: [],
      disabledPromoLinks: [],
      promoLinkItems: [],
      customData: {},
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
      gallery: null,
      newsletter: null,
      owlObjectId: null,
      stats: null,
      purchases: [],
      media: null,
      attributesStandard: {
        inTrending: true,
        bodyTextCharCount: 0,
        icon: ArticleIcon.None,
        geolocation: {
          latitude: 0,
          longitude: 0,
        },
        lockType: ArticleLockTypeDefault,
        smsCode: null,
        publicVisibleBodyCharCount: 0,
      },
      flagsStandard: {
        enableForum: false,
        enableMultiTitleTest: false,
        enableAds: true,
        enableAdsInContent: true,
        enableVideoAdsInContent: true,
        prAuthor: false,
        enablePromoLinks: true,
      },
      activeStages: [],
      completedStages: [],
      relatedToMe: [],
      relatedArticles: [],
      canonicalUrl: '',
      hasComments: false,
      articleVersions: [],
      mainPage: null,
      firstPublishedBy: null,
      designSettings: null,
      thirdPartyTrackers: [],
      _system: SYSTEM_CMS,
      _resourceName: ENTITY_STANDARD,
    }
  }

  const createArticleTexts = (): ArticleTexts => {
    const { createEmptyDocument } = useDocumentFactory()

    return {
      headline: '',
      overline: '',
      perex: '',
      body: createEmptyDocument(),
    }
  }

  const createArticleSeo = (): ArticleSeo => {
    return {
      title: '',
      description: '',
      slug: '',
    }
  }

  const createArticleDates = (): ArticleDates => {
    return {
      publishedAt: null,
      firstPublishedAt: null,
      expireAt: null,
      publicPublishedAt: dateTimeNow(),
      publicUpdatedAt: null,
    }
  }

  const createArticleFlags = (): ArticleFlagsStandard => {
    return {
      enableAds: true,
      enableAdsInContent: true,
      enableVideoAdsInContent: true,
      enablePromoLinks: true,
      prAuthor: false,
      enableForum: true,
      enableMultiTitleTest: false,
    }
  }

  const createArticleRelatedEntities = (): ArticleRelatedEntities => {
    return {
      person: null,
      pages: [],
    }
  }

  return {
    createArticle,
    createArticleTexts,
    createArticleSeo,
    createArticleDates,
    createArticleFlags,
    createArticleRelatedEntities,
  }
}
