<script lang="ts" setup>
import { ADialogToolbar } from '@anzusystems/common-admin'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    title?: string
    message?: string
    show?: boolean
    confirmActionTitle?: string
    cancelActionTitle?: string
  }>(),
  {
    title: '',
    message: '',
    show: false,
    confirmActionTitle: '',
    cancelActionTitle: '',
  }
)

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'cancel'): void
}>()

const isVisible = computed({
  get: () => props.show,
  set: (val) => {
    if (!val) emit('cancel')
  }
})

const confirm = () => {
  emit('confirm')
  isVisible.value = false
}

const cancel = () => {
  emit('cancel')
  isVisible.value = false
}

const { t } = useI18n()
</script>

<template>
  <VDialog
    v-model="isVisible"
    :width="400"
  >
    <VCard>
      <ADialogToolbar @on-cancel="cancel">
        {{ title || t('common.confirmDialog.title') }}
      </ADialogToolbar>
      <VCardText>
        {{ message || t('common.confirmDialog.message') }}
      </VCardText>
      <VCardActions>
        <VSpacer />
        <ABtnSecondary
          class="mx-1"
          @click.stop="cancel"
        >
          {{ cancelActionTitle || t('common.confirmDialog.action.cancel') }}
        </ABtnSecondary>
        <ABtnPrimary
          class="mx-1"
          @click.stop="confirm"
        >
          {{ confirmActionTitle || t('common.confirmDialog.action.cancel') }}
        </ABtnPrimary>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
