import { i18n } from '@anzusystems/common-admin'
import { createI18nMessage, helpers } from '@vuelidate/validators'

const { t } = i18n.global

export function useValidateDocId() {
  const withI18nMessage = createI18nMessage({ t })

  return withI18nMessage(helpers.regex(/^[0-9a-f]{8}(?:-[0-9a-f]{4}){2}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i), {
    messagePath: () => 'error.jsValidation.invalidDocId',
  })
}
