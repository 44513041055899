<script lang="ts" setup>
import { computed } from 'vue'
import type { ThirdPartyTrackerServiceType } from '@/types/cms/ThirdPartyTracker'
import { useThirdPartyTrackerService } from '@/model/cms/valueObject/ThirdPartyTrackerService'

const props = withDefaults(
  defineProps<{
    service: ThirdPartyTrackerServiceType
  }>(),
  {}
)

const { getThirdPartyTrackerServiceOption } = useThirdPartyTrackerService()
const serviceOption = computed(() => {
  return getThirdPartyTrackerServiceOption(props.service)
})
</script>

<template>
  <VChip
    v-if="serviceOption"
    label
    size="small"
    :color="serviceOption.color"
  >
    {{ serviceOption.title }}
  </VChip>
</template>
