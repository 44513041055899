<script lang="ts" setup>
import { AActionCloseButton, AActionSaveButton, ACard, defineBreadcrumbs } from '@anzusystems/common-admin'
import { computed, onBeforeUnmount } from 'vue'
import { ROUTE } from '@/router/routes'
import ActionbarWrapper from '@/components/wrappers/ActionbarWrapper.vue'
import NotificationManage from '@/views/cms/notification/components/NotificationManage.vue'
import { useNotificationCreateEditActions } from '@/views/cms/notification/composables/notificationActions'
import { useI18n } from 'vue-i18n'

const { resetStore, onCreate, notification } = useNotificationCreateEditActions()

const { t } = useI18n()
const breadcrumbs = defineBreadcrumbs(
  computed(() => [
    { title: t('breadcrumb.cms.notification.list'), routeName: ROUTE.CMS.NOTIFICATION.LIST },
    {
      title: notification.value.texts.headline || t('breadcrumb.cms.notification.create'),
      routeName: ROUTE.CMS.FAQ.CREATE,
    },
  ])
)

onBeforeUnmount(() => {
  resetStore()
})
</script>

<template>
  <ActionbarWrapper :breadcrumbs="breadcrumbs">
    <template #buttons>
      <AActionSaveButton @save-record="onCreate" />
      <AActionCloseButton :route-name="ROUTE.CMS.NOTIFICATION.LIST" />
    </template>
  </ActionbarWrapper>

  <ACard>
    <VCardText>
      <NotificationManage />
    </VCardText>
  </ACard>
</template>
