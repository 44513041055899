<script lang="ts" setup>
import { COMMON_CONFIG, type DocId } from '@anzusystems/common-admin'
import { computed, watch } from 'vue'
import { useArticleFindActions } from '@/views/cms/article/composables/articleActions'
import { useRouter } from 'vue-router'
import { ROUTE } from '@/router/routes'

const props = withDefaults(
  defineProps<{
    docId: DocId
  }>(),
  {}
)
const docId = computed(() => props.docId)
const { findByDocId, foundArticle, findArticleLoading } = useArticleFindActions()
watch(
  docId,
  (newDocId) => {
    findByDocId(newDocId)
  },
  { immediate: true }
)

const router = useRouter()
const onClick = () => {
  if (foundArticle.value && false === findArticleLoading.value) {
    router.push({ name: ROUTE.CMS.ARTICLE.DETAIL, params: { id: foundArticle.value.id } })
  }
}
</script>

<template>
  <VChip
    size="small"
    label
    :append-icon="COMMON_CONFIG.CHIP.ICON.LINK"
    @click.stop="onClick"
  >
    {{ foundArticle?.texts.headline ?? docId }}
    <VProgressCircular
      v-if="findArticleLoading"
      indeterminate
      :size="12"
      :width="2"
    />
  </VChip>
</template>
