import { useContentItemKindFactory } from '@/model/cms/factory/ContentItemKind/ContentItemKindFactory'
import { ContentItemDiscriminator } from '@/types/cms/ContentItemKind/ContentItemKind'
import type { ContentItemKindUserWeather } from '@/types/cms/ContentItemKind/ContentItemKindUserWeather'
import { LanguageDefault } from '@/model/cms/valueObject/Language.ts'

const { createAbstractContentItemKind } = useContentItemKindFactory()

export function useContentItemKindUserWeatherFactory() {
  const create = (): ContentItemKindUserWeather => {
    return {
      ...createAbstractContentItemKind(),
      locationId: null,
      title: '',
      url: '',
      locationUrl: '',
      locationSevenDaysUrl: '',
      locationNineDaysUrl: '',
      locationFifteenDaysUrl: '',
      language: LanguageDefault,
      discriminator: ContentItemDiscriminator.UserWeather,
      _resourceName: 'contentItemKindUserWeather',
    }
  }

  return {
    create,
  }
}
