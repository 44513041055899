import { SYSTEM_CMS } from '@/model/systems'
import { dateTimeNow } from '@anzusystems/common-admin'

import type { EmbedMinuteAware, EmbedMinuteCreateUpdateDto } from '@/components/anzutap/types/EmbedMinute'

export function useEmbedMinuteFactory() {
  const createDefault = (id = ''): EmbedMinuteAware => {
    return {
      id,
      customData: {},
      discriminator: 'minute',
      _resourceName: 'embedKindMinute',
      _system: SYSTEM_CMS,
      createdAt: dateTimeNow(),
      modifiedAt: dateTimeNow(),
      createdBy: null,
      modifiedBy: null,
    }
  }

  const createCreateUpdateDto = (id = ''): EmbedMinuteCreateUpdateDto => {
    return {
      id,
      customData: {},
    }
  }

  return {
    createDefault,
    createCreateUpdateDto,
  }
}
