import type { SortableItemDataAware } from '@anzusystems/common-admin'

export const ThirdPartyTrackerService = {
  Gemius: 'gemius',
  AdForm: 'adForm',
  DoubleClick: 'doubleClick',
  Nielsen: 'nielsen',
} as const
export const ThirdPartyTrackerServiceDefault = ThirdPartyTrackerService.Gemius
export type ThirdPartyTrackerServiceType = (typeof ThirdPartyTrackerService)[keyof typeof ThirdPartyTrackerService]

export interface ThirdPartyTrackerDto extends SortableItemDataAware {
  service: ThirdPartyTrackerServiceType
  param: string
  new: boolean
}

export interface ThirdPartyTrackerUpsertDto {
  thirdPartyTrackers: ThirdPartyTrackerDto[]
}
