<script lang="ts" setup>
import { ACopyText, ARow } from '@anzusystems/common-admin'
import CoreTrackingFields from '@/components/cms/CoreTrackingFields.vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useNotificationFamily } from '@/model/cms/valueObject/NotificationFamily.ts'
import { useNotificationPriority } from '@/model/cms/valueObject/NotificationPriority'
import { useNotificationOneStore } from '@/stores/cms/notificationStore'
import CachedSiteChip from '@/views/cms/site/components/CachedSiteChip.vue'
import CachedSiteGroupChip from '@/views/cms/siteGroup/components/CachedSiteGroupChip.vue'
import { useNotificationDestination } from '@/model/cms/valueObject/NotificationDestination.ts'

const { notification } = storeToRefs(useNotificationOneStore())

const { t } = useI18n()

const { getNotificationFamilyOption } = useNotificationFamily()
const notificationFamilyOption = computed(() => {
  return getNotificationFamilyOption(notification.value.family)
})

const { getNotificationPriorityOption } = useNotificationPriority()
const notificationPriorityOption = computed(() => {
  return getNotificationPriorityOption(notification.value.priority)
})

const { getNotificationDestinationOption } = useNotificationDestination()
const notificationDestinationOption = computed(() => {
  return getNotificationDestinationOption(notification.value.destination)
})
</script>

<template>
  <VRow>
    <VCol cols="8">
      <ARow :title="t('cms.notification.model.id')">
        <ACopyText :value="notification.id" />
      </ARow>
      <ARow :title="t('cms.notification.model.siteGroup')">
        <CachedSiteGroupChip :id="notification.siteGroup" />
      </ARow>
      <ARow
        class="pl-2"
        :title="t('cms.notification.model.site')"
      >
        <CachedSiteChip :id="notification.site" />
      </ARow>
      <ARow
        :title="t('cms.notification.model.texts.overline')"
        :value="notification.texts.overline"
      />
      <ARow
        :title="t('cms.notification.model.texts.headline')"
        :value="notification.texts.headline"
      />
      <ARow
        :title="t('cms.notification.model.texts.summary')"
        :value="notification.texts.summary"
      />
      <ARow
        v-if="notificationFamilyOption"
        :title="t('cms.notification.model.notificationFamily')"
        :value="notificationFamilyOption.title"
      />
      <ARow
        :title="t('cms.notification.model.articleDocId')"
        :value="notification.articleDocId"
      />
      <ARow
        :title="t('cms.notification.model.url')"
        :value="notification.url"
      />
      <ARow
        v-if="notificationPriorityOption"
        :title="t('cms.notification.model.notificationPriority')"
        :value="notificationPriorityOption.title"
      />
      <ARow
        v-if="notificationDestinationOption"
        :title="t('cms.notification.model.notificationDestination')"
        :value="notificationDestinationOption.title"
      />
      <CoreTrackingFields :data="notification" />
    </VCol>
  </VRow>
</template>
